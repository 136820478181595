import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ErrorHandler from "./ErrorHandler.jsx";

import Sticky from 'react-stickynode';
import { connect } from "react-redux";
import { getFile, setAvailableCountries, setAvailableDates, setCountries, setLoading, hideLoading, processFiles, setError, skipVATNumber } from "../redux/actions.js";

import {humanDate} from "./utils.js";

import ERRORS from "../errors.js";
import Summary from "./summary.jsx";

import ViewTab from "./viewer/ViewTab.jsx";
import TabContainers from "./TabContainers.jsx";

const styles = theme => ({
  root: {
      flexGrow: 1,
      position: "relative"
  },
  tabs: {
      //position: "sticky",
      //top: theme.mixins.toolbar.minHeight + 7,
      zIndex: theme.zIndex.drawer + 1
  },
  flexContainer: {
      overflow: "auto"
  }
});

const EXTRA_TABS = ["TOTAL", "SUMMARY"];

class YearTabs extends React.Component {
    constructor() {
        super();

        this.state = {
            value: 0,
            key: "TOTAL"
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.years) != JSON.stringify(this.props.years)) {
            let keys = this.getKeys();
            let value = this.state.value >= keys.length ? keys.length - 1 : this.state.value;
            let key = keys[value];

            this.setState({ key });
            this.props.onSelect(value, key);
        }
    }

    handleChange(event, value) {
        let keys = this.getKeys();

        this.setState({value, key: keys[value]});
        this.props.onSelect(value, keys[value]);
    }

    getKeys() {
        return [].concat(this.props.years).concat(EXTRA_TABS);
    }

    createTabs() {
        let keys = this.getKeys();

        if (this.state.value != 0 && keys.length - 1 < this.state.value) {
            let index = Math.min(this.state.value, Math.max(0, keys.length - 1));

            this.setState({
                value: index,
                key: keys[index]
            });
        }

        return keys.map(x => {
            return <Tab key={x} label={x} />
        });
    }

    render() {
        const { classes, years, calculations } = this.props;
        const { value, key } = this.state;

        return (<>
             <Sticky activeClass="active no-print" enabled={true} top={64} innerZ={1201}>
                <AppBar position="static" className={classes.tabs + " no-print"} elevation={0}>
                  <Tabs value={value} onChange={this.handleChange.bind(this)} classes={{flexContainer: classes.flexContainer}}>
                    { this.createTabs() }
                  </Tabs>
                </AppBar>
             </Sticky>
          </>);
    }
}

const mapStateToProps = state => {
    return { };
};

const mapDispatchToProps = dispatch => {
    return {
        setCountries: countries => dispatch(setCountries(countries)),
        setAvailableCountries: countries => dispatch(setAvailableCountries(countries)),
        setAvailableDates: dates => dispatch(setAvailableDates(dates)),
        setLoading: progress => dispatch(setLoading(progress)),
        hideLoading: () => dispatch(hideLoading()),
        processFiles: (files, dates, countries, vatRates, skippedVATNumbers) => dispatch(processFiles(files, dates, countries, vatRates, skippedVATNumbers)),
        setError: message => dispatch(setError(message)),
        skipVATNumber: vatNumber => dispatch(skipVATNumber(vatNumber))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(YearTabs));
