import React from 'react';
import ReactDOM from 'react-dom';

import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';

import { connect } from "react-redux";

const styles = theme => ({
    progressBar: {
        position: "relative",
      zIndex: theme.zIndex.drawer + 3,
    },
});

function ProgressBar(props) {
    const {classes} = props;

    if (props.loading) {
        if (props.progress < 0) {
            return <LinearProgress color="secondary" />
        }

        return <LinearProgress color="secondary" variant="determinate" value={ props.progress } />
    } else {
        return null;
    }
}

const mapStateToProps = state => {
  return { loading: state.loading, progress: state.loadingProgress };
};

export default connect(mapStateToProps)(withStyles(styles)(ProgressBar));
