import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from "react-redux";

import AppBar from '@material-ui/core/AppBar';
import Tb from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import Settings from "./settings.jsx";

import { setCountries, setDateRanges, setClientView, toggleDarkMode, setRemoveDuplicates, setClientInfo } from "../redux/actions.js";

import MultiSelectMenu from "./menus/MultiSelectMenu.jsx";
import ProgressBar from '../ProgressBar.jsx';
import ToggleButton from "./menus/ToggleButton.jsx";

import ClientInfoLoader from "../core/ClientInfoLoader.js";
import OpenFileButton from "../uploader/OpenFileButton.jsx";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    flex: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    progressBar: {
        position: "absolute",
        zIndex: theme.zIndex.drawer + 3,
        width: "100%",
        bottom: 0
    },
});

class Toolbar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
            settingsAnchor: null,
            open: false,
            frmCountryOpen: false,
            setDateRangesOpen: false,
            dateRanges: [0]
        }
    }

    handleSettingsClick(event) {
        const { currentTarget } = event;

        this.setState(state => ({
            settingsAnchor: currentTarget,
            open: !state.open,
        }));
    }

    handleClickAway() {
        this.setState({
            open: false,
        });
    };

    handleClick(event) {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose(target, e, selected) {
        if (target == "date-selector")
            this.setState({ dateRanges: selected, anchorEl: null });
        else {
            this.setState({ selectedCountries: selected, anchorEl: null });
        }

        this.updateStore(target, selected);
    }

    async handleLoadClientInfo(e) {
        let clientInfoLoader = new ClientInfoLoader();
        await clientInfoLoader.read(e.target.files[0]);

        this.props.setClientInfo(clientInfoLoader.serialize());
    }

    updateStore(target, selected) {
        if (target == "date-selector") {
            let dates = [];
            if (selected.findIndex(i => this.dateOptions[i].selectAll) > -1) {
                dates = null;
            } else {
                dates = selected.filter(i => !this.dateOptions[i].selectAll).map(i => this.dateOptions[i].name);
            }
            this.props.setDateRanges(dates);
        } else {
            let countries = [];

            if (selected.findIndex(i => this.countryOptions[i].selectAll) > -1) {
                countries = null;
            } else {
                countries = selected.filter(i => !this.countryOptions[i].selectAll).map(i => this.countryOptions[i].name);
            }
            this.props.setCountries(countries);
        }
    }

    print() {
        window.print();
    }

    render() {
        const { anchorEl} = this.state;
        const { classes, clientInfo } = this.props;
        this.countryOptions = [
            {name: 'Show all',
             divider: true,
             selectAll: true}
        ];

        for (let aC of this.props.availableCountries) {
            this.countryOptions.push({name: aC});
        }

        this.dateOptions = [
            {name: 'Show all',
            selectAll: true,
            divider: true},
        ];

        for (let date of this.props.availableDates) {
            this.dateOptions.push({name: date.getFullYear()});
        }

        let selectedCountries;
        if (this.props.selectedCountries == null) {
            selectedCountries = [0];
        } else {
            for (let s of this.props.selectedCountries) {
                selectedCountries.push(this.countryOptions.findIndex(x => x.name == s));
            }
        }

        let dateRanges;
        if (this.props.dateRanges == null) {
            dateRanges = [0];
        } else {
            for (let s of this.props.dateRanges) {
                dateRanges.push(this.dateOptions.findIndex(x => x.name == s));
            }
        }

        //console.log(this.props.dateRanges, this.dateOptions.map(x => x.name), dateRanges);

        return (<React.Fragment>
            <AppBar position="fixed" className={ this.props.classes.appBar + " no-print" }>
                    <Tb>
                      <Typography variant="h5" component="h1" color="inherit" className={this.props.classes.flex}>
                        DST Checker (All-to-One)
                      </Typography>
                      <OpenFileButton id="load-client-info" color={clientInfo.clientName ? "secondary" : "inherit"} onChange={this.handleLoadClientInfo.bind(this)}>{clientInfo.clientName ? clientInfo.clientName : "Load Client Info"}</OpenFileButton>
                      <Button id="toggle-dark-mode" color={this.props.darkMode ? "secondary" : "inherit"} onClick={e => this.props.toggleDarkMode()}>Dark Mode</Button>
                      <Button id="toggle-client-view" color={this.props.clientView ? "secondary" : "inherit"} onClick={e => this.props.setClientView(!this.props.clientView)} >Client View</Button>
                      <Button id="download-pdf" color="inherit" onClick={this.print.bind(this)} >Download PDF</Button>
                      <Button id="toggle-remove-duplicates" color={this.props.removeDuplicates ? "secondary" : "inherit"} onClick={e => this.props.setRemoveDuplicates(!this.props.removeDuplicates)}>Remove Duplicates</Button>

                      <ClickAwayListener onClickAway={this.handleClickAway.bind(this)}>
                        <IconButton
                          aria-label="More"
                          aria-owns={this.state.open ? 'long-menu' : null}
                          aria-haspopup="true"
                          color="inherit"
                          onClick={this.handleSettingsClick.bind(this)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </ClickAwayListener>
                        <Settings anchorEl={this.state.settingsAnchor} open={this.state.open}/>
                    </Tb>
                    <div className={classes.progressBar}>
                        <ProgressBar />
                    </div>
                </AppBar>
            </React.Fragment>)
    }
}

const mapStateToProps = state => {
    return {availableDates: state.availableDates,
            availableCountries: state.availableCountries,
            selectedCountries: state.selectedCountries,
            dateRanges: state.dateRanges,
            clientView: state.clientView,
            darkMode: state.darkMode,
            removeDuplicates: state.removeDuplicates,
            clientInfo: state.clientInfo }
}

const mapDispatchToProps = dispatch => {
    return {
        setCountries: countries => dispatch(setCountries(countries)),
        setDateRanges: dates => dispatch(setDateRanges(dates)),
        setClientView: flag => dispatch(setClientView(flag)),
        toggleDarkMode: e => dispatch(toggleDarkMode()),
        setRemoveDuplicates: flag => dispatch(setRemoveDuplicates(flag)),
        setClientInfo: clientInfo => dispatch(setClientInfo(clientInfo))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Toolbar));
