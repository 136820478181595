import React, { Suspense } from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import Divider from "@material-ui/core/Divider"

import LinearProgress from "@material-ui/core/LinearProgress"
import TopBar from "./TopBar.jsx"
import SectionBase from "./SectionBase.jsx"

const useStyles = makeStyles(theme => ({
  content: {
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2, 2)
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2, 4)
    }
  }
}))

const Section = props => {
  const classes = useStyles()

  const { isLoading, children } = props

  return (
    <SectionBase>
      {isLoading && <LinearProgress color="primary" />}
      {!isLoading && <TopBar {...props} />}
      {!isLoading && <Divider />}
      {!isLoading && (
        <Suspense fallback={<LinearProgress color="primary" />}>
          <div className={classes.content}>{!isLoading && children}</div>
        </Suspense>
      )}
    </SectionBase>
  )
}

Section.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
}

export default Section
