import React from "react"
import PropTypes from "prop-types"
import { Link as RouterLink } from "react-router-dom"
import UILink from "@material-ui/core/Link"

const Link = props => {
  const { native = false, to, ...rest } = props

  return <UILink component={native ? null : RouterLink} to={to} {...rest} />
}

Link.propTypes = {
  to: PropTypes.node.isRequired,
  native: PropTypes.bool
}

export default Link
