import React from 'react';
import ReactDOM from 'react-dom';

import Button from '@material-ui/core/Button';

import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Divider from '@material-ui/core/Divider';

export default class MultiSelectMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: [...(this.props.selectedItems || [])]
        }
    }

    getSelectedIds() {
        return this.state.selected;
    }

    getSelectedKeys() {
        return this.props.options.filter((x, i) => this.state.selected.indexOf(i) != -1);
    }

    handleMenuItemClick(event, index) {
        var selected = [];

        if (this.state.selected.indexOf(index) != -1) {
            if (!this.props.options[index].selectAll) {
                selected = this.state.selected.filter(i => i != index && !this.props.options[i].selectAll);
            } else {
                selected = [];
            }
        } else {
            if (this.props.options[index].selectAll) {
                selected = this.props.options.map((x, i) => (i));
            } else {
                selected = [...this.state.selected, index];
            }
        }

        if (this.props.onChange)
            this.props.onChange(this.props.target, index, selected);

        this.setState({ selected });
    };

    onClose(e) {
        this.props.onClose(this.props.target, e, this.state.selected);
    }

    onEnter(e) {
        let sel = this.props.selectedItems;

        for (let i of this.props.selectedItems) {
            if (this.props.options[i].selectAll) {
                sel = this.props.options.map((x, i) => (i));

                break;
            }
        }

        this.setState({selected: sel});
    }

    render() {
        return (
            <Menu onEnter={e => this.onEnter(e) } anchorEl={this.props.anchorEl} open={Boolean(this.props.anchorEl) && this.props.anchorEl.id == this.props.target} onClose={e => this.onClose(e)}>
                { this.props.options.map((option, index) => (
                    <React.Fragment key={option.name}>
                      <MenuItem key={option.name} selected={this.state.selected.indexOf(index) != -1} onClick={event => this.handleMenuItemClick(event, index)}>{ option.name }</MenuItem>
                      { (() => {
                          if (option.divider)
                            return <Divider light/>
                      })()}
                    </React.Fragment>
                  ))
                }
            </Menu>
        )
    }
}
