import React from 'react';

import {withStyles} from '@material-ui/core/styles';
import DialogBoxGenerator from "../../common/DialogBoxGenerator";

const styles = theme => ({
    paper: {
        position: 'absolute',
        width: "50%",
        height: "50%",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    }
});


class NewDirectoryModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            departureCountry: ""
        }
    }

    onChange(e) {
        this.setState({departureCountry: e.target.value});
    }

    render() {
        const {classes} = this.props;

        return <DialogBoxGenerator
          {...this.props}
          title="Add new directory"
          fields={[
            {
              type: "text",
              label: "Directory name",
              id: "directoryName"
            }
          ]}
        ></DialogBoxGenerator>
    }
}

export default withStyles(styles)(NewDirectoryModal);
