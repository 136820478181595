const formidable = require('formidable');
const fs = require('fs');
const path = require('path');

const UPLOADS_DIR = "uploads/";

const AWS = require("aws-sdk");
const fetch = require("node-fetch");

const BUCKET = process.env.BUCKET_NAME || "dst-checker-uploads-staging";

const xml2js = require("xml2js");

const s3 = new AWS.S3({
    signatureVersion: 'v4',
    region: 'eu-west-2'
});

async function uploadS3({
    fileName,
    buffer,
    contentType,  
}) {
    const key = [fileName]
      .filter(Boolean) // This allows us to filter falsy values (f.ex. empty documentType)
      .join("/");

    const signedUrl = await s3.getSignedUrlPromise("putObject", {
        Bucket: BUCKET,
      Expires: 1 * 3600,
      Key: key
      // Tagging: tagging
      /* Great! This is an undocumented feature as of 27th May 2020: https://docs.aws.amazon.com/AmazonS3/latest/API/API_PutObject.html. 
      Took some time to figure out we had to specify "Tagging" as a param and as a header to get rid of the "SignatureDoesNotMatch" error.
      */
    });

    const response = await fetch(signedUrl, {
      method: "PUT",
      body: buffer
    });

    // TODO: If resopnse is not ok, parse XML and return AWS error code.

    return !!response.ok;
  };

async function upload(req, res) {
    const { filename } = req.params;

    let result = await uploadS3({
        fileName: decodeURIComponent(filename),
        buffer: req.rawBody,
    });

    res.status(200).json(result).end();

    /*var form = new formidable.IncomingForm();
    form.parse(req, function(err, fields, files) {
        if (!files.file) {
            console.log("Error " + err.message);
            return;
        }
        // `file` is the name of the <input> field of type `file`
        var old_path = files.file.path,
            file_size = files.file.size,
            file_ext = files.file.name.split('.').pop(),
            index = old_path.lastIndexOf('/') + 1,
            file_name = files.file.name,
            new_path = path.join(process.cwd(), '/' + UPLOADS_DIR, file_name);

        fs.readFile(old_path, function(err, data) {
            fs.writeFile(new_path, data, function(err) {
                fs.unlink(old_path, function(err) {
                    if (err) {
                        res.status(500);
                        res.json({'success': false});
                    } else {
                        res.status(200);
                        res.json({'success': true});
                    }
                });
            });
        });
    });*/
}

function getFilesizeInBytes(filename) {
    var stats = fs.statSync(filename);
    var fileSizeInBytes = stats["size"];
    return fileSizeInBytes;
}

/*function getUploads(req, res) {
    fs.readdir("./" + UPLOADS_DIR, (err, files) => {
        res.json(files.map(x => ({name: x, size: getFilesizeInBytes("./" + UPLOADS_DIR + "/" + x)})));
        res.end();
    });
}*/

async function getUploads(req, res) {
    const signedUrl = await s3.getSignedUrlPromise("listObjects", {
        Bucket: BUCKET,
        Expires: 1 * 3600,
        //Prefix: String(prefix),
        //...(limit && { MaxKeys: limit })
    });

    const response = await fetch(signedUrl);
    const listBucketResultXml = await response.text();

    const listBucketResult = await xml2js.parseStringPromise(listBucketResultXml, {
        trim: true,
        explicitRoot: false,
        explicitArray: false,
        valueProcessors: [
            xml2js.processors.parseBooleans,
            xml2js.processors.parseNumbers,
            value =>
            typeof value === "string" ? value.replace(/^"(.*)"$/, "$1") : value // Strip double quotes
        ],
        tagNameProcessors: [xml2js.processors.firstCharLowerCase]
    });

    let contents = listBucketResult.contents ? listBucketResult.contents : [];

    contents = Array.isArray(contents) ? contents : [contents];

    res.status(200).json(contents).end();
}

async function deleteFile(req, res) {
    try {
        const { filename } = req.params;

        const signedUrl = await s3.getSignedUrlPromise("deleteObject", {
            Bucket: BUCKET,
          Expires: 1 * 3600,
          Key: decodeURIComponent(filename)
          // Tagging: tagging
          /* Great! This is an undocumented feature as of 27th May 2020: https://docs.aws.amazon.com/AmazonS3/latest/API/API_PutObject.html. 
          Took some time to figure out we had to specify "Tagging" as a param and as a header to get rid of the "SignatureDoesNotMatch" error.
          */
        });
    
        const response = await fetch(signedUrl, {
          method: "DELETE",
        });

        res.json({success: true});
    } catch (e) {
        console.log("Failed " + e.message);

        res.status(200);
        res.json({success: false, message: e.message});
    }
}

async function getFile(req, res) {

    try {
        const { filename } = req.params;

        const signedUrl = await s3.getSignedUrlPromise("getObject", {
          Bucket: BUCKET,
          Expires: 1 * 3600,
          Key: decodeURIComponent(filename)
        });
    
        //const response = await fetch(signedUrl);

        res.send(signedUrl).status(200).end();
    } catch (e) {
        console.log("Failed " + e.message);

        res.status(200);
        res.json({success: false, message: e.message});
    }
}

module.exports = { upload, getUploads, deleteFile, getFile };
