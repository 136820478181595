import CSVtoArray from "./CSVtoArray.js";

function interpret(string) {
    let rows = [];
    let lines = string.split("\n");

    if (lines.length != 0) {
        let headers = CSVtoArray(lines[0])[0];

        for (var i = 1; i < lines.length; i++) {
            if (lines[i].length > 0) {
                rows.push(CSVtoArray(lines[i])[0]);
            }
        }

        return { headers, rows };
    }
}

export { interpret };
