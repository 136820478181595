const COUNTRY_INFO = {
    "AT": {
        NAME: "Austria",
        CODE: "AT",
        CURRENCY: "EUR",
        SYMBOL: "€",
        VAT: 0.20,
        DST: 35000,
        DRC: false
    },
    "BE": {
        NAME: "Belgium",
        CODE: "BE",
        CURRENCY: "EUR",
        SYMBOL: "€",
        VAT: 0.21,
        DST: 35000,
        DRC: true
    },
    "BG": {
        NAME: "Bulgaria",
        CODE: "BG",
        CURRENCY: "BGN",
        SYMBOL: "bg",
        VAT: 0.20,
        DST: 70000,
        DRC: false
    },
    "HR": {
        NAME: "Croatia",
        PAYPAL_NAME: "Croatia, Republic of",
        CODE: "HR",
        CURRENCY: "HRK",
        SYMBOL: "hr",
        VAT: 0.25,
        DST: 270000,
        DRC: false
    },
    "CY": {
        NAME: "Cyprus",
        CODE: "CY",
        CURRENCY: "EUR",
        SYMBOL: "€",
        VAT: 0.19,
        DST: 35000,
        DRC: false
    },
    "CZ": {
        NAME: "Czech Republic",
        CODE: "CZ",
        CURRENCY: "CZK",
        SYMBOL: "czk",
        VAT: 0.21,
        DST: 1140000,
        DRC: false
    },
    "DK": {
        ENABLED: false,
        NAME: "Denmark",
        CODE: "DK",
        CURRENCY: "DKK",
        SYMBOL: "dk",
        VAT: 0.25,
        DST: 280000,
        DRC: false
    },
    "EE": {
        NAME: "Estonia",
        CODE: "EE",
        CURRENCY: "EUR",
        SYMBOL: "€",
        VAT: 0.20,
        DST: 35000,
        DRC: false
    },
    "FI": {
        NAME: "Finland",
        CODE: "FI",
        CURRENCY: "EUR",
        SYMBOL: "€",
        VAT: 0.24,
        DST: 35000,
        DRC: false
    },
    "FR": {
        NAME: "France",
        SIMPLE_NAME: "France",
        CODE: "FR",
        CURRENCY: "EUR",
        SYMBOL: "€",
        VAT: 0.2,
        DST: 35000,
        YEARS: {
            "2015": 100000
        },
        DRC: true
    },
    "DE": {
        NAME: "Germany",
        CODE: "DE",
        CURRENCY: "EUR",
        SYMBOL: "€",
        VAT: 0.19,
        DST: 100000,
        DRC: false
    },
    "GR": {
        NAME: "Greece",
        CODE: "GR",
        CURRENCY: "EUR",
        SYMBOL: "€",
        VAT: 0.24,
        DST: 35000,
        DRC: false
    },
    "HU": {
        NAME: "Hungary",
        CODE: "HU",
        CURRENCY: "HUF",
        SYMBOL: "huf",
        VAT: 0.27,
        DST: 8800000,
        DRC: false
    },
    "IE": {
        NAME: "Ireland",
        CODE: "IE",
        CURRENCY: "EUR",
        SYMBOL: "€",
        VAT: 0.23,
        DST: 35000,
        DRC: true
    },
    "IM": {
        NAME: "Isle of Man",
        CODE: "IM",
        CURRENCY: "GBP",
        SYMBOL: "€",
        VAT: 0.20,
        DST: 82000,
        JURIS: "GB",
        DRC: false
    },
    "IT": {
        NAME: "Italy",
        CODE: "IT",
        CURRENCY: "EUR",
        SYMBOL: "€",
        VAT: 0.22,
        DST: 35000,
        DRC: true
    },
    "LV": {
        NAME: "Latvia",
        CODE: "LV",
        CURRENCY: "EUR",
        SYMBOL: "€",
        VAT: 0.21,
        DST: 35000,
        DRC: false
    },
    "LT": {
        NAME: "Lithuania",
        CODE: "LT",
        CURRENCY: "EUR",
        SYMBOL: "€",
        VAT: 0.21,
        DST: 35000,
        DRC: false
    },
    "LU": {
        NAME: "Luxembourg",
        CODE: "LU",
        CURRENCY: "EUR",
        SYMBOL: "€",
        VAT: 0.17,
        DST: 100000,
        DRC: false
    },
    "MT": {
        NAME: "Malta",
        CODE: "MT",
        CURRENCY: "EUR",
        SYMBOL: "€",
        VAT: 0.18,
        DST: 35000,
        DRC: false
    },
    "MC": {
        NAME: "Monaco",
        CODE: "MC",
        CURRENCY: "EUR",
        SYMBOL: "€",
        VAT: 0.2,
        DST: 35000,
        JURIS: "FR",
        DRC: true
    },
    "NL": {
        NAME: "Netherlands",
        CODE: "NL",
        CURRENCY: "EUR",
        SYMBOL: "€",
        VAT: 0.21,
        DST: 100000,
        DRC: false
    },
    "PL": {
        NAME: "Poland",
        CODE: "PL",
        CURRENCY: "PLN",
        SYMBOL: "zl",
        VAT: 0.23,
        DST: 160000,
        DRC: false
    },
    "PT": {
        NAME: "Portugal",
        CODE: "PT",
        CURRENCY: "EUR",
        SYMBOL: "€",
        VAT: 0.23,
        DST: 35000,
        DRC: false
    },
    "RO": {
        NAME: "Romania",
        CODE: "RO",
        CURRENCY: "RON",
        SYMBOL: "ron",
        VAT: 0.19,
        DST: 118000,
        DRC: false
    },
    "SK": {
        NAME: "Slovakia",
        CODE: "SK",
        CURRENCY: "EUR",
        SYMBOL: "€",
        VAT: 0.20,
        DST: 35000,
        DRC: false
    },
    "SI": {
        NAME: "Slovenia",
        CODE: "SI",
        CURRENCY: "EUR",
        SYMBOL: "€",
        VAT: 0.22,
        DST: 35000,
        DRC: false
    },
    "ES": {
        NAME: "Spain",
        CODE: "ES",
        CURRENCY: "EUR",
        SYMBOL: "€",
        VAT: 0.21,
        DST: 35000,
        DRC: true
    },
    "SE": {
        NAME: "Sweden",
        CODE: "SE",
        CURRENCY: "SEK",
        SYMBOL: "kr",
        VAT: 0.25,
        DST: 320000,
        DRC: false
    },
    "GB": {
        NAME: "United Kingdom",
        SIMPLE_NAME: "United Kingdom",
        CODE: "GB",
        CURRENCY: "GBP",
        SYMBOL: "£",
        VAT: 0.2,
        DST: 70000,
        DRC: false
    },
    "ROW": {
        NAME: "Rest of the World",
        CODE: "ROW",
        CURRENCY: "USD",
        SYMBOL: "$",
        VAT: 1,
        DST: 0,
        DRC: false
    }
};

export default {
    ...COUNTRY_INFO,
    getDST: (countryCode, year) => {
        let country = COUNTRY_INFO[countryCode];
        if (country.YEARS) {
            for (let yea of Object.keys(country.YEARS).map(x => parseInt(x)).sort((a,b) => b - a)) {
                if (year <= yea) {
                    return country.YEARS["" + yea];
                }
            }
        }

        return country.DST;
    }
}
