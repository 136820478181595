import React from 'react';
import ReactDOM from 'react-dom';

import { connect } from "react-redux";
import { getFile, setAvailableCountries, setAvailableDates, setDates, setCountries, setLoading, hideLoading, queueProcess, processFiles, setError, skipVATNumber } from "../redux/actions.js";

import ErrorBox from "../ErrorBox.jsx";
import ERRORS from "../errors.js";

import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

export default class ErrorHandler extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {error} = this.props;
        if (error.code == ERRORS.ERROR_VIES_CHECK_TIMEOUT) {
            let vatIdCode = error.vatId.substring(0, 2);
            return <ErrorBox open={error != ''}
                      error={error}
                      actions={[
                          <Button
                            key="Skip"
                            aria-label="Skip"
                            color="secondary"
                            onClick={e => this.props.handleClose(e, "skip", error)}
                          >Skip</Button>,
                          <Button
                            key="Skip"
                            aria-label="Skip"
                            color="secondary"
                            onClick={e => this.props.handleClose(e, "skip-all-" + vatIdCode, error)}
                          >Skip all {vatIdCode}</Button>,
                          <Button
                            key="Skip"
                            aria-label="Skip"
                            color="secondary"
                            onClick={e => this.props.handleClose(e, "skip-all", error)}
                          >Skip all</Button>,
                          <Button
                            key="retry"
                            aria-label="Retry"
                            color="secondary"
                            onClick={e => this.props.handleClose(e, "retry", error)}
                          >Retry</Button>,
                        <IconButton
                          key="close"
                          aria-label="Close"
                          color="inherit"
                          onClick={e => this.props.handleClose(e, "error", error)}
                        >
                          <CloseIcon />
                        </IconButton>,
                    ]} />
        } else {
            return <ErrorBox open={error != ''}
                      error={error}
                      actions={[
                          <Button
                            key="retry"
                            aria-label="Retry"
                            color="secondary"
                            onClick={e => this.props.handleClose(e, "retry", error)}
                          >Retry</Button>,
                        <IconButton
                          key="close"
                          aria-label="Close"
                          color="inherit"
                          onClick={e => this.props.handleClose(e, "error", error)}
                        >
                          <CloseIcon />
                        </IconButton>,
                    ]} />
        }
    }
}
