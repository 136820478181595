import React from 'react';
import ReactDOM from 'react-dom';

import { connect } from "react-redux";

import CountrySettings from "../country-settings.js";

import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import SortableTableWithHeader from "./tables/SortableTableWithHeader.jsx";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import red from '@material-ui/core/colors/red';

import { humanDate } from "./utils.js";

const styles = theme => ({
    root: {
        paddingTop: theme.spacing.unit * 3,
        paddingBottom: theme.spacing.unit * 3,
        paddingLeft: theme.spacing.unit * 3,
        paddingRight: theme.spacing.unit * 3,
        width: "100%"
    },
    padding: {
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2
    },
    paddingTop: {
        paddingTop: theme.spacing.unit * 2,
    },
    paddingBottom: {
        paddingBottom: theme.spacing.unit * 2
    },
    invalid: {
        backgroundColor: red[300]
    }
});

const Row = withStyles(styles)(function Row(props) {
    const { row, index, classes } = props;

    return <TableRow className={!row.valid ? classes.invalid : null}>
                <TableCell>{ row.id }</TableCell>
                <TableCell>{ row.valid ? "Yes" : "No" }</TableCell>
                <TableCell>{ row.reason }</TableCell>
                <TableCell>{ Object.keys(row.files).map(x => <>{ x }<br/></>) }</TableCell>
                <TableCell>{ Object.keys(row.files).map(x => <>{ row.files[x].rows.sort((a, b) => a - b).join(", ") }<br/></>) }</TableCell>
           </TableRow>
});

class VIESChecksTable extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { viesChecks } = this.props;

        let paginationOptions = [5, 10, 25, 50, 100];
        paginationOptions = paginationOptions.map(x => x <= viesChecks.length ? x : null).filter(x => x != null);
        if (viesChecks.length > paginationOptions[paginationOptions.length - 1]) {
            paginationOptions.push(viesChecks.length);
        }

        return <SortableTableWithHeader
            headers={
                [{ id: 'vrn', numeric: false, disablePadding: false, label: 'VAT Number' },
                 { id: 'valid', numeric: false, disablePadding: false, label: 'Valid' },
                 { id: 'reason', numeric: false, disablePadding: false, label: 'Reason' },
                 { id: 'files', numeric: false, disablePadding: false, label: 'File(s)' },
                 { id: 'rows', numeric: false, disablePadding: false, label: 'Row(s)' }]
            }
            startOrderBy="files"
            data={viesChecks}
            paginate={paginationOptions.length > 0}
            paginationOptions={paginationOptions}
            paginationStartRows={5}
            row={(row, i) => {
                return <Row row={row} index={i} />
            }}
            title="VIES Checks"
            elevation={0}
        />
    }
}

const mapStateToProps = (state, ownProps) => {
    return { selectedFiles: state.selectedFiles,
             selectedDates: state.selectedDates,
             selectedCountries: state.selectedCountries,
             error: state.error };
};

export default connect(mapStateToProps, null)(withStyles(styles)(VIESChecksTable));
