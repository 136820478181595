import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from "react-redux";
import CountrySettings from "../../country-settings.js";
import View from "./View.jsx";
import ClientView from "./clients/ClientView.jsx";

var merge = require('lodash.merge');

class DSTView extends React.Component {
    constructor(props) {
        super(props);

        this.state = { calculations: { months: [], results: {} } };
    }

    sortIntoJurisdictions(country, allCountryData, current) {
        let countryObj = CountrySettings[country];

        let res;
        let calculations = allCountryData[country];
        if (countryObj.JURIS) {
            if (!current[countryObj.JURIS]) {
                current[countryObj.JURIS] = {
                    dst: calculations.dst,
                    mainland: {},
                    jurisdictions: [
                        {
                            country: countryObj,
                            calcs: calculations[country]
                        }
                    ]
                }
            } else {
                current[countryObj.JURIS].jurisdictions.push({
                    country: countryObj,
                    calcs: calculations[country]
                })
            }
        }

        if (!current[country]) {
            current[country] = {
                dst: calculations.dst,
                mainland: calculations,
                jurisdictions: []
            }
        } else {
            current[country].mainland = calculations;
        }

        return current;

        /*return Object.keys(calculations)
            .map(x => CountrySettings[x])
            .sort((a, b) => a.NAME.localeCompare(b.NAME))
            .reduce((acc, val) => {
                if (val.JURIS) {
                    let found = acc[val.JURIS];

                    if (!found) {
                        acc[val.JURIS] = { mainland: { country: CountrySettings[val.JURIS], calcs: calculations[val.JURIS] }, jurisdictions: [{ country: val, calcs: calculations[val.CODE] }] };
                    } else {
                        acc[val.JURIS].jurisdictions.push({ country: val, calcs: calculations[val.CODE] });
                    }
                } else {
                    acc[val.CODE] = { mainland: { country: val, calcs: calculations[val.CODE] }, jurisdictions: [] };
                }

                return acc;
            }, {});*/
    }

    sort(countriesInData) {
        return Object.keys(countriesInData)
                .reduce((acc, val) => {
                    acc = this.sortIntoJurisdictions(val, countriesInData, acc);
                    return acc;
                }, {});
    }

    render() {
        let { data } = this.props;

        let months = (data.months || []).sort((a, b) => a - b);

        if (this.props.clientView) {
            return <ClientView stats={this.props.stats} date={this.props.date} months={months} calculations={this.sort(data.calculations || {})} originalData={this.props.data} years={this.props.years}/>
        } else {
            return <View stats={this.props.stats} date={this.props.date} months={months} calculations={this.sort(data.calculations || {})} originalData={this.props.data} years={this.props.years}/>
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return { clientView: state.clientView,
             vatRates: state.vatRates,
             showOnlyExceedingDST: state.showOnlyExceedingDST };
};

export default connect(mapStateToProps, null)(DSTView);
