import React from 'react';
import ReactDOM from 'react-dom';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },

});

class ToggleButton extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <Button id={this.props.id} color="secondary" onClick={this.props.onClick} >{this.props.children}</Button>
    }
}

export default withStyles(styles)(ToggleButton);
