import { createStore, applyMiddleware } from "redux";
import rootReducer from "./root-reducer.js";
import createSagaMiddleware from 'redux-saga';
import mySaga from './sagas';

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

const store = createStore(rootReducer,
              applyMiddleware(sagaMiddleware));

// then run the saga
sagaMiddleware.run(mySaga);

export default store;
