import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import YearTabs from "../YearTabs.jsx";
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        flexGrow: 1,
        position: "relative"
    },
    tabs: {
        position: "sticky",
        top: theme.mixins.toolbar.minHeight + 7,
        zIndex: theme.zIndex.drawer + 1,
    }
});

class ClientYearTabs extends React.Component {
    constructor() {
        super();
    }

    render() {
        const { classes, salesDataProcessed, processTotal, skippedVATNumbers, salesData, clientView } = this.props;
        let results = this.props.salesData;
        let c = salesDataProcessed.total;

        return <div className={classes.root + " print-full-width"}>
          <YearTabs salesDataProcessed={salesDataProcessed} processTotal={processTotal} skippedVATNumbers={skippedVATNumbers} salesData={salesData} clientView={clientView}/>
        </div>
    }
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ClientYearTabs));
