const express = require("express");
const path = require('path');
const bodyParser = require('body-parser');
const cors = require('cors');

const app = express();

const { upload, getUploads, deleteFile, getFile } = require("./uploads.js");

function createServer(root, port, cb) {
    app.use(cors());

    app.use(bodyParser.json());
    app.use(bodyParser.urlencoded({ extended: true }));
    app.use(express.raw({
        limit: "5000mb",
        type: ["text/plain", // txt
        "text/csv"], // csv],
        verify: (req, res, buf, encoding) => {
            req.rawBody = buf;
          }
    }));

    app.use('/src', express.static(root + '/src'));

    app.get("/", (req, res) => {
        res.sendFile(path.join(root + '/index.html'));
    });

    app.get('/api/uploads', getUploads);
    app.get('/api/upload/:filename', getFile);
    app.put('/api/upload/:filename', upload);
    app.delete('/api/delete/:filename', deleteFile);

    return app.listen(port, (app => () => {
        if (cb)
            cb(app);
    })(app));
}

module.exports = createServer;
