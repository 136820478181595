import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from "react-redux";

import Grid from '@material-ui/core/Grid';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import grey from '@material-ui/core/colors/red';

import PhoneIcon from '@material-ui/icons/Phone.js';
import EmailIcon from '@material-ui/icons/Email.js';

import CustomTableCell from "./CustomTableCell.jsx";

import { getFile, setAvailableCountries, setAvailableDates, setDates, setCountries, setLoading, hideLoading, queueProcess, processFiles, setError, skipVATNumber } from "../../../redux/actions.js";

import CountryStock from "./CountryStock.jsx";
import DSTExceeded from "./DSTExceeded.jsx";
import DSTClose from "./DSTClose.jsx";
import CountrySettings from "../../../country-settings.js";
import MessageBox from "../../../MessageBox.jsx";
import PrintableView from "../PrintableView.jsx";

import { calculateVAT } from "../../../core/vat-functions.js";
import { humanDate } from "../../utils.js";

const styles = theme => ({
    margins: {
        paddingLeft: theme.spacing.unit * 1,
        paddingRight: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 1,
        marginBottom: theme.spacing.unit * 1,
    },
    description: {
        paddingLeft: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 2,
        display: "flex",
        flex: 1,
        alignItems: "center"
    },
    centered: {
        display: "flex",
        flex: 1,
        alignItems: "center"
    },
    titleCentered: {
        display: "flex",
        justifyContent: "center",
        width: "100%"
    },
    heading: {
        paddingLeft: theme.spacing.unit * 2.5,
        paddingRight: theme.spacing.unit * 2.5,
        paddingTop: theme.spacing.unit * 0.5,
        paddingBottom: theme.spacing.unit * 1.5,
        color: theme.palette.type == "dark" ? theme.palette.primary.contrastText : null,
    },
    padding: {
        paddingTop: theme.spacing.unit * 2,
        //paddingBottom: theme.spacing.unit * 2,
        //paddingLeft: theme.spacing.unit * 2,
        //paddingRight: theme.spacing.unit * 2,
    },
    contentMargin: {
        marginTop: theme.spacing.unit * 1
    },
    header: {
        paddingTop: 15,
        paddingBottom: 15,
        paddingLeft: theme.spacing.unit * 2.5,
        paddingRight: theme.spacing.unit * 2.5,
        width: "100%",
        backgroundColor: theme.palette.primary.main,
        borderRadius: 0,
        display: "flex",
        alignItems: "center"
    },
    table: {
        //"word-break": "break-word"
    },
    footer: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
        width: "100%",
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 0,
    },
    whiteText: {
        color: theme.palette.primary.contrastText,
    },
    titleText: {
        color: theme.palette.primary.contrastText,
        width: 300
    },
    footerText: {
        color: theme.palette.primary.contrastText,
        fontSize: 18,
        display: "flex"
    },
    logo: {
        width: 130,
        marginRight: theme.spacing.unit * 2.5,
    }
});

function toCommas(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const MIN_CLOSE_PERC = 75;

class ClientView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            progress: 0,
            loading: false,
            dataLen: 0,
            errMessage: '',
            hasMessage: false,
            message: ''
        }

        this.finished = 0;

        this.countryData = [];
        this.availableCountries = [];
        this.availableDates = [];

        this.stock = [];
        this.dsts = [];
        this.close = [];
    }

    message(msg) {
        this.setState({hasMessage: true, message: msg});
    }

    processData() {
        const { calculations, stats, date, years } = this.props;

        this.stock = [];
        this.dsts = [];
        this.close = [];

        for (var arrivalCountry in calculations) {
            let destCountry = CountrySettings[arrivalCountry];

            let minDate = stats[arrivalCountry].minSaleFromDate;

            let { dst } = calculations[arrivalCountry];
            let { dstCrossedDate} = dst;
            let net = dst.net + dst.dstNet;

            let DST = CountrySettings.getDST( arrivalCountry, date );

            if (dstCrossedDate) {
                this.dsts.push({destination: destCountry.SIMPLE_NAME || destCountry.NAME,
                                currency: destCountry.CURRENCY,
                                dst: toCommas(DST),
                                netSales: toCommas(net.toFixed(2)),
                                dateCrossed: humanDate(dstCrossedDate) });
            } else if (date !== "TOTAL" || years.length === 1) {
                let dstPerc = Math.floor((net / DST) * 100);
                if (dstPerc >= MIN_CLOSE_PERC) {
                    this.close.push({destination: destCountry.SIMPLE_NAME || destCountry.NAME,
                                    currency: destCountry.CURRENCY,
                                    dst: toCommas(DST),
                                    netSales: toCommas(net.toFixed(2)),
                                    percent: dstPerc });
                }
            }

            for (var depCountry in calculations[arrivalCountry].mainland) {
                let srcCountry = CountrySettings[depCountry];
                if (!srcCountry) 
                    continue;

                let dateOfFirstSale = stats[depCountry].minSaleFromDate;
                let name = srcCountry.SIMPLE_NAME || srcCountry.NAME;

                if (dateOfFirstSale && !this.stock.find(x => x.name == name))
                    this.stock.push({ name, dateOfFirstSale });
            }
        }
    }

    stockCountries() {
        if (!this.props.calculations)
            return [];

        return this.stock.map(x => <CountryStock data={x} />);
    }

    DSTsExceeded() {
        if (!this.props.calculations)
            return [];

        return this.dsts.map(x => <DSTExceeded data={x} />);
    }

    DSTsClose() {
        if (!this.props.calculations)
            return [];

        return this.close.map(x => <DSTClose data={x} />);
    }

    showStockCountries() {
        const { classes } = this.props;

        let stockCountries = this.stockCountries();
        if (stockCountries.length > 0) {
            return <Grid container spacing={0} className="item">
                <Grid item xs={12} className={classes.margins}>
                    <Paper className={classes.padding} elevation={1}>
                        <Typography className={classes.heading} variant="h6" gutterBottom>Where's my stock?</Typography>
                        <Divider className={classes.contentMargin}/>
                        <Table className={classes.table}>
                            <TableHead>
                              <TableRow>
                                <CustomTableCell>Country</CustomTableCell>
                                <CustomTableCell>Date of First Sale</CustomTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                                {stockCountries}
                            </TableBody>
                        </Table>
                    </Paper>
                </Grid>
            </Grid>
        }

        return null;
    }

    showDSTsExceeded() {
        const { classes } = this.props;

        let dstsExceeded = this.DSTsExceeded();

        if (dstsExceeded.length > 0) {
            return <Grid container spacing={0} className="item">
                <Grid item xs={12} className = { classes.margins}>
                    <Paper className = { classes.padding } elevation={1}>
                        <Typography className={classes.heading} variant="h6" gutterBottom>Which Distance Selling Thresholds have I crossed?</Typography>
                        <Divider className={classes.contentMargin}/>
                        <Table className={classes.table}>
                            <TableHead>
                              <TableRow>
                                <CustomTableCell>Country</CustomTableCell>
                                <CustomTableCell>Currency</CustomTableCell>
                                <CustomTableCell>Threshold</CustomTableCell>
                                <CustomTableCell>Net Sales</CustomTableCell>
                                <CustomTableCell>Date Crossed</CustomTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                                {dstsExceeded}
                            </TableBody>
                        </Table>
                    </Paper>
                </Grid>
            </Grid>
        }

        return null;
    }

    showDSTsClose() {
        const { classes, date } = this.props;

        let dstsClose = this.DSTsClose();
        if (dstsClose.length > 0) {
            return <Grid container spacing={0} className="item">
                <Grid item xs={12} className={classes.margins}>
                    <Paper className={classes.padding} elevation={1}>
                        <Typography className={classes.heading} variant="h6" gutterBottom>Where am I close to crossing?</Typography>
                        <Divider className={classes.contentMargin}/>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                  <CustomTableCell>Country</CustomTableCell>
                                  <CustomTableCell>Currency</CustomTableCell>
                                  <CustomTableCell>Threshold</CustomTableCell>
                                  <CustomTableCell>Net Sales</CustomTableCell>
                                  <CustomTableCell>Percentage</CustomTableCell>
                                  { (() => (date == "TOTAL2" ? <CustomTableCell>Year</CustomTableCell> : null))() }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dstsClose}
                            </TableBody>
                        </Table>
                    </Paper>
                </Grid>
            </Grid>
        }

        return null;
    }

    render() {
        const { classes, date } = this.props;

        this.processData();

        let dstCountries = this.DSTsExceeded();
        let dstCloseCountries = this.DSTsClose();

        return (<PrintableView header={<header>
                    <Paper className={classes.header} elevation={0}>
                        <Grid container spacing={0} className="print-page-break">
                            <Grid item xs={3} className={classes.centered}>
                                <img class={classes.logo} src="assets/images/simplyvat-logo.png" />
                            </Grid>

                            <Grid item xs={6} className={classes.centered}>
                                <div  className={classes.titleCentered}>
                                    <Typography variant="h4" className={classes.titleText}>SimplyVAT.com Health Check</Typography>
                                </div>
                            </Grid>
                            <Grid item xs={3} className={classes.centered}>
                                <Typography variant="h5" className={classes.whiteText} style={{textAlign: "center", width: "100%"}}>{date == "TOTAL" ? "All Years" : date}</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </header>}
            footer={
                <footer style={{flexGrow: "0"}}>
                    <Paper className={classes.footer} elevation={0}>
                        <Grid container spacing={0} className="print-page-break">
                            <Grid item xs={7} className={classes.margins+" "+classes.centered}>
                                <Typography className={classes.whiteText} variant="h5">Give us a call, we're here to help!</Typography>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography className={classes.footerText} variant="h6" ><PhoneIcon style={{marginRight: 10}}/> +44 (0)1273 634594</Typography>
                                <Typography className={classes.footerText} variant="h6" ><EmailIcon style={{marginRight: 10}}/> heretohelp@simplyvat.com</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </footer>
            }><main style={{ flexGrow: "1" }}>
                {this.showStockCountries()}
                {this.showDSTsExceeded()}
                {this.showDSTsClose()}

                <MessageBox onClose={e => this.setState({hasMessage: false}) } open={ this.state.hasMessage } message={this.state.message} />
            </main>
        </PrintableView>)
    }
}

const mapStateToProps = (state, ownProps) => {
    return { selectedFiles: state.selectedFiles,
             error: state.error,
             vatRates: state.vatRates };
};

export default connect(mapStateToProps, null)(withStyles(styles)(ClientView));
