export default class Customizer {
    constructor() {}

    visitFile(file) {}
    visitEndFile(file) {}
    visitYear(year) {}
    visitEndYear(year) {}
    visitDepartureCountry(departureCountry) {}
    visitEndDepartureCountry(departureCountry) {}
    visitArrivalCountry(arrivalCountry) {}
    visitEndArrivalCountry(arrivalCountry) {}
    visitDate(date) {}
    visitEndDate(date) {}
    visitCurrency(currency) {}
    visitEndCurrency(currency) {}
    visitId(id) {}
    visitEndId(id) {}
    visit({ file, year, departureCountry, arrivalCountry, date, currency, id, gross, rows, sku }) {}

    end() {}
}
