import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2, 2)
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(5, 5)
    },
    // padding: theme.spacing(5, 5),
    display: "flex",
    flex: "1 1 auto",
    alignItems: "center",
    paddingBottom: theme.spacing(3)
    // background: theme.palette.primary.main,
    // color: "white"
  },
  title: {
    flexGrow: 1
  }
}))

const TopBar = props => {
  const classes = useStyles()

  const { title, header } = props

  return (
    <div className={classes.root}>
      <Typography variant="h4" component="h1" align="left" className={classes.title}>
        {title}
      </Typography>
      {header}
    </div>
  )
}

TopBar.propTypes = {
  title: PropTypes.string.isRequired,
  header: PropTypes.node
}

export default TopBar
