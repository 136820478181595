import React, { useState } from "react";

import TextField from '@material-ui/core/TextField';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import InputLabel from '@material-ui/core/InputLabel';

import Select from '@material-ui/core/Select';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Button from '@material-ui/core/Button';

import Checkbox from '@material-ui/core/Checkbox';

import DialogBox from "./DialogBox";

import {
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

export default function DialogBoxGenerator(props) {
    const [values, setValues] = useState({});
    const handleChange = e => {
        const { name, value, type, checked } = e.target;

        if (type == "checkbox") {
            setValues({...values, [name]: checked});
        } else {
            setValues({...values, [name]: value});
        }
    };

    const handleDateChange = name => e => {
        setValues({...values, [name]: e});
    }

    return <DialogBox {...props} actions={[
        <Button onClick={e => props.handleClose(values)}>Add</Button>,
        <Button onClick={e => props.handleClose(null)}>Cancel</Button>,
    ]}>
        {props.fields.map(x => {
            if (x.type == "select") {
                return <FormControl fullWidth required={x.required}>
                    <InputLabel id={x.id + "-label"}>{x.label}</InputLabel>

                    <Select
                      labelId={x.id + "-label"}
                      id={x.id}
                      name={x.id}
                      value={values[x.id]}
                      onChange={handleChange}
                      required={x.required}
                    >{ x.data.map(x => {
                        return <MenuItem value={x.id}>{x.label}</MenuItem>
                    }) }
                </Select>
                </FormControl>
            } else if (x.type == "date") {
                return <KeyboardDatePicker
                  disableToolbar
                  margin="normal"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id={x.id}
                  label={x.label}
                  value={values[x.id]}
                  onChange={handleDateChange(x.id)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  fullWidth
                  required={x.required}
                />
            } else if (x.type == "checkbox") {
                return <FormControlLabel
                    control={
                      <Checkbox checked={values[x.id]} name={x.id} onChange={handleChange} value={x.id} />
                    }
                    label={x.label}
                    required={x.required}
                  />
            } else {
                return <TextField
                    fullWidth
                    required={x.required}
                    id={x.id}
                    name={x.id}
                    label={x.label}
                    onChange={handleChange}
                    value={values[x.id]}
                    validators={['required']}
                    errorMessages={['this field is required', 'email is not valid']}
                />
            }
        })}
    </DialogBox>
}
