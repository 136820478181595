import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from "react-redux";
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const styles = theme => ({
});

class PrintableView extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { classes } = this.props;

        return (<div>
                    <table class="report-container">
                       <thead class="report-header">
                         <tr>
                            <th class="report-header-cell">
                                {this.props.header}
                            </th>
                      </tr>
                </thead>
                <tfoot class="report-footer">
                    <tr>
                        <td class="report-footer-cell" style={{position: "relative"}}>
                            {this.props.footer}
                        </td>
                    </tr>
                </tfoot>
                <tbody class="report-content">
                    <tr>
                        <td class="report-content-cell">
                            {this.props.children}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>)
    }
}

export default connect(null, null)(withStyles(styles)(PrintableView));
