import React from 'react';
import Summary from "./summary.jsx";

import { connect } from "react-redux";
import ViewTab from "./viewer/ViewTab.jsx";

const mergeWith = require('lodash.mergewith');
const uniq = require('lodash.uniq');

class YearTabs extends React.Component {
    constructor() {
        super();
    }

    tabContainer(year, data, stats, years) {
        return <ViewTab fx={this.props.fx} date={year} data={data || {}} stats={stats || {}} years={years}/>
    }

    createTabContainers() {
        const {classes, calculations, stats, years, index} = this.props;

        let x = index;

        if (x == "TOTAL") {
            let calcs = {};
            let combinedStats = {};

            function customizer(a, b, key) {
                if (key === "months") {
                    return !a ? [].concat(b) : [ ...a, ...b ];
                } else if (key == "dstCrossedDate") {
                    return a < b ? a : b;
                }
                if (typeof a === 'number' && typeof b === 'number') {
                    return a + b;
                }
            }

            function customizer2(a, b, key) {
                if (typeof a === 'number' && typeof b === 'number') {
                    return a + b;
                } else if (a instanceof Date && b instanceof Date) {
                    return a < b ? a : b;
                }
            }

            for (var year in calculations) {
                calcs = mergeWith(calcs, calculations[year], customizer);
                combinedStats = mergeWith(combinedStats, stats[year], customizer2);
            }

            calcs.months = uniq(calcs.months);

            return this.tabContainer(x, calcs, combinedStats.countryInfo, years);
        } else if (x == "SUMMARY") {
            const { minDate, ids, viesChecks, rowsProcessed, duplicates } = this.props;
            return <Summary firstSale={minDate}
                     duplicates={ids}
                     viesChecks={viesChecks}
                     skippedVATNumbers={[]}
                     rowsProcessed={rowsProcessed}
                     duplicates={duplicates}
                     />
        } else {
            return this.tabContainer(x, calculations[x], (stats[x] || {countryInfo: {}}).countryInfo);
        }
    }

    render() {
        const { classes } = this.props;

        return <>{ this.createTabContainers() }</>;
    }
}

const mapStateToProps = state => {
    return { clientView: state.clientView, calculations: state.dsts, stats: state.stats };
};

const mapDispatchToProps = dispatch => {
    return {  };
}

export default connect(mapStateToProps, mapDispatchToProps)(YearTabs);
