const getFile = file => ({type: "GET_FILE", file});
const customFileData = (desc, data) => ({type: "CUSTOM_FILE_DATA", desc, data});
const selectFile = index => ({type: "SELECT_FILE", index});
const deselectFile = index => ({type: "DESELECT_FILE", index});

const deleteFile = desc => ({type: "DELETE_FILE", desc});

const setAvailableCountries = countries => ({type: "SET_AVAILABLE_COUNTRIES", countries});
const setAvailableDates = dates => ({type: "SET_AVAILABLE_DATES", dates});
const setCountries = countries => ({type: "SET_COUNTRIES", countries});
const setDateRanges = ranges => ({type: "SET_DATE_RANGES", dateRanges: ranges});
const setOnlyExceedingDSTs = flag => ({type: "SET_ONLY_EXCEEDING_DSTS", flag});

const setLoading = progress => ({type: "SET_LOADING", progress});
const hideLoading = () => ({type: "HIDE_LOADING"});

const processFile = (file) => ({type: "PROCESS_FILE", file });

const processFiles = (files, dates, skippedVATNumbers) => ({type: "PROCESS_FILES", files, dates, skippedVATNumbers });
const clearSalesDataTotal = () => ({type: "CLEAR_SALES_DATA_TOTAL"});
const updateCountry = (data, desc, rate, date) => ({type: "UPDATE_COUNTRY", data, desc, customVAT: rate, date, updateProcessedSales: true });
const updateProcessedSales = () => ({type: "UPDATE_PROCESSED_SALES" });
const setError = message => ({type: "ERROR", error: message });

const setVATRate = (country, rate) => ({type: "SET_VAT_RATE", country, rate});

const skipVATNumber = vatNumber => ({type: "SKIP_VAT_NUMBER", vatNumber});

const askFor = data => ({type: "ASK_FOR", data});
const shiftAskFor = () => ({type: "SHIFT_ASK_FOR"});

const setClientView = flag => ({type: "SET_CLIENT_VIEW", flag});

const toggleDarkMode = e => ({type: "TOGGLE_DARK_MODE"});

const setRemoveDuplicates = flag => ({type: "SET_REMOVE_DUPLICATES", flag});
const setClientInfo = clientInfo => ({type: "SET_CLIENT_INFO", clientInfo});

export {getFile,
        customFileData,
        selectFile,
        deselectFile,
        deleteFile,
        setAvailableCountries,
        setAvailableDates,
        setCountries,
        setDateRanges,
        setOnlyExceedingDSTs,
        setLoading,
        hideLoading,
        processFile,
        processFiles,
        clearSalesDataTotal,
        updateCountry,
        updateProcessedSales,
        setError,
        setVATRate,
        skipVATNumber,
        askFor,
        shiftAskFor,
        setClientView,
        toggleDarkMode,
        setRemoveDuplicates,
        setClientInfo};
