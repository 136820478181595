import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import View from "./View.jsx";
import DSTView from "./DSTView.jsx";

const styles = theme => ({
    viewer: {
        height: "100%",
    }
});

function ViewTab(props) {
    return <DSTView {...props} />
}

export default withStyles(styles)(ViewTab);
