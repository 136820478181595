import CountrySettings from "../country-settings.js";
import Customizer from "./Customizer.js";

var merge = require('lodash.merge');
var mergeWith = require('lodash.mergewith');

const VAT_RATE_MODS = {
    DE: [{
        startDate: new Date("2020-07-01 00:00:00"),
        endDate: new Date("2020-12-31 24:00:00"),
        oldRate: 0.19,
        newRate: 0.16
    }],
    IE: [{
        startDate: new Date("2020-09-01 00:00:00"),
        endDate: new Date("2021-02-28 24:00:00"),
        oldRate: 0.23,
        newRate: 0.21
    }]
}

function getVATRate(country, date, sku, vatRatesMap, customRates) {
    let defaultRate = typeof customRates[country] == "undefined" ? CountrySettings[country].VAT : customRates[country];
    let vatRates = vatRatesMap[country];
    let rate = !vatRates || typeof vatRates[sku] === "undefined" ? defaultRate : vatRates[sku];

    return VAT_RATE_MODS[country]?.find(({
        startDate,
        endDate,
        oldRate
    }) => {
        if (rate === oldRate && date >= startDate && date <= endDate) {
            return true;
        }
    })?.newRate ?? defaultRate;
}

class VATMultiCalculation {
    constructor(country, vatRates, defaultRate) {
        this.country = country;
        this.rateMods = VAT_RATE_MODS[country];

        this.gross = 0;
        this.net = 0;
        this.vat = 0;

        this.vatRates = vatRates || {};
        this.defaultRate = defaultRate;
    }

    add(gross, sku, date) {
        let rate = typeof this.vatRates[sku] === "undefined" ? this.defaultRate : this.vatRates[sku];

        let moddedRate = this.rateMods?.find(({
            startDate,
            endDate,
            oldRate
        }) => {
            if (rate === oldRate && date >= startDate && date <= endDate) {
                return true;
            }
        })?.newRate ?? this.defaultRate;

        let net = gross / (1 + moddedRate);

        this.gross += gross;
        this.net += net;
        this.vat += net * moddedRate;
    }

    set(gross, net, vat) {
        this.gross = gross;
        this.net = net;
        this.vat = vat;
    }

    serialize() {
        return { gross: this.gross, net: this.net, vat: this.vat };
    }
}

class DSTCalculation {
    constructor(DST, frm, to, vatRatesMap, crossedDate) {
        this.DST = DST;
        this.frm = frm;
        this.to = to;

        this.pre = new VATMultiCalculation(this.frm.CODE, vatRatesMap[this.frm.CODE], this.frm.VAT);
        this.post = new VATMultiCalculation(this.to.CODE, vatRatesMap[this.to.CODE], this.to.VAT);

        this.vatRatesMap = vatRatesMap;
        this.crossedDate = crossedDate;
    }

    setCrossedDate(crossedDate) {
        this.crossedDate = crossedDate;
    }

    serialize() {
        return { gross: this.pre.gross + this.post.gross, net: this.pre.net, vat: this.pre.vat,
                 dstGross: this.post.gross, dstNet: this.post.net, dstVat: this.post.vat,
                 dstCrossedDate: this.crossedDate };
    }

    add(gross, sku, date) {
        if (this.crossedDate) {
            this.post.add(gross, sku, date);
            if (date && this.post.net < 0) {
                this.pre.add(gross, sku, date);
                this.post.add(-gross, sku, date);

                this.crossedDate = null;
            }
        } else {
            this.pre.add(gross, sku, date);
            if (date && this.pre.net >= this.DST) {
                this.pre.add(-gross, sku, date);
                this.post.add(gross, sku, date);

                this.crossedDate = date;
            }
        }
    }
}

class DSTCombinedCountriesCalculation {
    constructor(DST, to, vatRatesMap, customRates, crossedDate) {
        this.DST = DST;
        this.to = to;

        this.pre = {
            gross: 0,
            net: 0,
            vat: 0
        }

        this.post = {
            gross: 0,
            net: 0,
            vat: 0
        }

        this.domestic = {
            gross: 0,
            net: 0,
            vat: 0
        }

        //this.post = new VATMultiCalculation(this.to.CODE, vatRatesMap[this.to.CODE], this.to.VAT);

        this.vatRatesMap = vatRatesMap;
        this.customRates = customRates;
        this.crossedDate = crossedDate;
    }

    setCrossedDate(crossedDate) {
        this.crossedDate = crossedDate;
    }

    serialize() {
        return { gross: this.pre.gross + this.post.gross, net: this.pre.net, vat: this.pre.vat,
                 dstGross: this.post.gross, dstNet: this.post.net, dstVat: this.post.vat,
                 domGross: this.domestic.gross, domNet: this.domestic.net, domVat: this.domestic.vat,
                 dstCrossedDate: this.crossedDate };
    }

    addToObj(gross, to, date, sku, obj) {
        let rate = getVATRate(to, date, sku, this.vatRatesMap, this.customRates);

        obj.gross += gross;
        obj.net += gross / (1 + rate);
        obj.vat = obj.net * rate;
    }

    add(gross, sku, date, frm, to) {
        if (frm === to)
            return this.addToObj(gross, to, date, sku, this.domestic);

        if (this.crossedDate) {
            this.addToObj(gross, to, date, sku, this.post);

            if (date && this.post.net < 0) {
                this.addToObj(gross, frm, date, sku, this.pre);
                this.addToObj(-gross, to, date, sku, this.post);

                this.crossedDate = null;
            }
        } else {
            this.addToObj(gross, frm, date, sku, this.pre);
            if (date && this.pre.net >= this.DST) {
                this.addToObj(-gross, frm, date, sku, this.pre);
                this.addToObj(gross, to, date, sku, this.post);

                this.crossedDate = date;
            }
        }
    }
}

class DSTMatrix {
    constructor(fx, clientInfo) {
        this.fx = fx;
        this.clientInfo = clientInfo;
        this.matrix = {};
    }

    initPath(year, frm, to, customRates) {
        let vatRates = this.clientInfo.vatRates;

        let frmCountry = CountrySettings[frm];
        let toCountry = CountrySettings[to];

        let frmRate = typeof customRates[frm] == "undefined" ? frmCountry.VAT : customRates[frm];
        let toRate = typeof customRates[to] == "undefined" ? toCountry.VAT : customRates[to];

        let newFrmCountry = { ...frmCountry, VAT: frmRate };
        let newToCountry = { ...toCountry, VAT: toRate };

        let DST = CountrySettings.getDST(toCountry.CODE, year);

        if (!this.matrix[to]) {
            this.matrix[to] = {
                dstCrossedCalculation: new DSTCombinedCountriesCalculation(DST, to, vatRates, customRates)
            };
        }

        if (!this.matrix[to][frm]) {
            if (frm == to) {
                this.matrix[to][frm] = {[frmCountry.CURRENCY]: new VATMultiCalculation(frm, vatRates[frm], frmRate)};
            } else {
                this.matrix[to][frm] = {[toCountry.CURRENCY]: new DSTCalculation(DST, newFrmCountry, newToCountry, vatRates),
                                        [frmCountry.CURRENCY]: new DSTCalculation(DST, newFrmCountry, newToCountry, vatRates)};
            }
        }
    }

    async add(frm, to, gross, currency, date, sku) {
        if (!this.matrix[to] || !this.matrix[to][frm])
            return;

        let keys = Object.keys(this.matrix[to][frm]);
        let fxRates = await Promise.all(keys.map(x => this.fx.lookupAverage(date, currency, x)));
        fxRates = fxRates.reduce((acc, val, i) => {
            acc[keys[i]] = val;
            return acc;
        }, {});
        let target = this.matrix[to][frm][keys[0]];

        this.matrix[to].dstCrossedCalculation.add(gross * fxRates[keys[0]], sku, date, frm, to);

        target.add(gross * fxRates[keys[0]], sku, date);

        for (let i = 1; i < keys.length; i++) {
            let other = this.matrix[to][frm][keys[i]];

            other.setCrossedDate(target.crossedDate);
            other.add(gross * fxRates[keys[i]], sku, date);
        }
    }

    serialize() {
        let res = {};

        for (let to in this.matrix) {
            if (!res[to]) {
                res[to] = {
                    dst: this.matrix[to].dstCrossedCalculation.serialize()
                };
            }

            for (let frm in this.matrix[to]) {
                if (!CountrySettings[frm])
                    continue;

                res[to][frm] = {};

                for (let currency in this.matrix[to][frm]) {
                    res[to][frm][currency] = this.matrix[to][frm][currency].serialize();
                }
            }
        }

        return res;
    }
}

export default class DSTCustomizer extends Customizer {
    constructor(fx, customRates, removeDuplicates, clientInfo) {
        super();

        this.fx = fx;
        this.customRates = customRates;

        this.removeDuplicates = removeDuplicates;

        this.ids = {};

        this.results = {};
        this.duplicates = {};

        this.clientInfo = clientInfo;

        this.frm = null;
        this.to = null;

        this.yearObj = null;
        this.depCountryObj = null;
        this.arrCountryObj = null;

        this.dstCrossedDate = null;
    }

    visitYear(year) {
        if (!this.results[year]) {
            this.results[year] = { months: [], calculations: {} };
            this.results[year].calculations = new DSTMatrix(this.fx, this.clientInfo);
        }
        this.year = year;
        this.yearObj = this.results[year];
    }

    visitDate(date) {
        let mon = date.getMonth() + 1;

        if (this.yearObj.months.indexOf(mon) == -1)
            this.yearObj.months.push(mon);
    }

    visitDepartureCountry(departureCountry) {
        this.frm = CountrySettings[departureCountry];
        if (!this.frm)
            return true;

        this.depCountryObj = departureCountry;
    }

    visitArrivalCountry(arrivalCountry) {
        this.to = CountrySettings[arrivalCountry];
        if (!this.to)
            return true;
    }

    async visit({ file, id, gross, rows, date, currency, departureCountry, arrivalCountry, type, sku }) {
        if (!this.to || !this.frm)
            return;

        // || type === "FC_TRANSFER" || type === "INBOUND"

        if (this.ids[id]) {
            this.ids[id].gross += gross;
            this.ids[id].type = type;
            this.ids[id].count += rows.length;
            if (this.ids[id].files[file]) {
                let old = this.ids[id].files[file];
                this.ids[id].files[file] = { rows: [ ...old.rows, ...rows ] };
            } else {
                this.ids[id].files[file] = { rows: [ ...rows ] };
            }
        } else {
            this.yearObj.calculations.initPath(this.year, this.depCountryObj, arrivalCountry, this.customRates);

            if (this.to.JURIS) {
                this.yearObj.calculations.initPath(this.year, this.depCountryObj, this.to.JURIS, this.customRates);
            }

            this.ids[id] = { year: date.getFullYear(), type, gross, count: rows.length, departureCountry, arrivalCountry, date, currency, files: { [file]: { rows: [ ...rows ] } } };

            /*if (this.removeDuplicates) {
                await this.yearObj.calculations.add(departureCountry, arrivalCountry, gross / rows.length, currency, date, sku);

                if (this.to.JURIS) {
                    await this.yearObj.calculations.add(departureCountry, this.to.JURIS, gross / rows.length, currency, date, sku);
                }
            }*/
        }

        /*if (!this.removeDuplicates) {
            await this.yearObj.calculations.add(departureCountry, arrivalCountry, gross, currency, date, sku);

            if (this.to.JURIS) {
                await this.yearObj.calculations.add(departureCountry, this.to.JURIS, gross, currency, date, sku);
            }
        }*/
    }

    async end() {
        let originalIds = this.ids;

        let ids = Object.keys(this.ids);
        ids = ids.filter(x => {
            let files = Object.keys(this.ids[x].files);
            return files.length > 1 || this.ids[x].files[files[0]].rows.length > 1;
        });

        this.duplicates = ids.map(x => ({id: x, ...this.ids[x]}));
        this.ids = ids;

        for (var i of Object.keys(originalIds).sort((a, b) => originalIds[a].date - originalIds[b].date)) {
            let obj = originalIds[i];
            let { year, departureCountry, arrivalCountry, gross, currency, date, sku, count } = obj;
            let to = CountrySettings[arrivalCountry];

            if (this.removeDuplicates) {
                gross /= count;
            }

            await this.results[year].calculations.add(departureCountry, arrivalCountry, gross, currency, date, sku);

            if (to.JURIS) {
                await this.results[year].calculations.add(departureCountry, to.JURIS, gross, currency, date, sku);
            }
        }

        for (var year in this.results) {
            this.results[year].months = this.results[year].months.sort();
            this.results[year].calculations = this.results[year].calculations.serialize();
        }
    }
}
