import React from 'react';
import ReactDOM from 'react-dom';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

export default class UploadButton extends React.Component {
    constructor(props) {
        super(props);
        this.btnRef = React.createRef();
    }

    render() {
        return <Button {...this.props} 
          className={this.props.className} 
          variant="contained" 
          color="primary" 
          fullWidth
          onClick={e => {this.btnRef.current.click()}}>
          <input
            accept=".txt,.csv"
            onChange={e => this.props.onChange(e)}
            style={{ display: 'none' }}
            type="file"
            ref={this.btnRef}
            multiple
          />Upload
         </Button>
    }
}

export class UploadIconButton extends React.Component {
  constructor(props) {
      super(props);
      this.btnRef = React.createRef();
  }

  render() {
      return <IconButton {...this.props} 
        className={this.props.className} 
        variant="contained" 
        color="primary" 
        fullWidth
        onClick={e => {this.btnRef.current.click()}}>
        <input
          accept=".txt,.csv"
          onChange={e => this.props.onChange(e)}
          style={{ display: 'none' }}
          type="file"
          ref={this.btnRef}
          multiple
        />{this.props.children}
       </IconButton>
  }
}
