function pad(num, size = 2) {
    var s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
}

function dateStr(date) {
    return date.getFullYear() + "-" + pad(date.getMonth() + 1, 2) + "-" + pad(date.getDate(), 2);
}

function humanDate(date) {
    return pad(date.getDate(), 2) + "/" + pad(date.getMonth() + 1, 2) + "/" + date.getFullYear();
}

function objToArray(obj) {
    return Object.keys(obj).map(x => obj[x]);
}

function uniq_fast(a) {
    var seen = {};
    var out = [];
    var len = a.length;
    var j = 0;
    for(var i = 0; i < len; i++) {
         var item = a[i];
         if(seen[item] !== 1) {
               seen[item] = 1;
               out[j++] = item;
         }
    }
    return out;
}

export { pad, dateStr, humanDate, uniq_fast, objToArray };
