import React from 'react';
import ReactDOM from 'react-dom';

import { connect } from "react-redux";

import CountrySettings from "../country-settings.js";

import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import VIESChecksTable from "./VIESChecksTable.jsx";
import DuplicatesTable from "./DuplicatesTable.jsx";
import HeadedPaper from "./paper/HeadedPaper.jsx";

import { humanDate } from "./utils.js";

const styles = theme => ({
    root: {
        marginBottom: theme.spacing.unit * 2,
        width: "100%"
    },
    paddingHoriz: {
        paddingLeft: theme.spacing.unit * 3,
        paddingRight: theme.spacing.unit * 3,
    },
    paddingVert: {
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    paddingTop: {
        paddingTop: theme.spacing.unit * 2,
    },
    paddingBottom: {
        paddingBottom: theme.spacing.unit * 2
    }
});

class Summary extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { classes, viesChecks, firstSale, rowsProcessed } = this.props;
        let duplicates = this.props.duplicates;

        const OutputDate = () => {
            if (firstSale) {
                return <Typography variant="headline" component="h2">Date of first sale: { humanDate(firstSale) }</Typography>
            } else {
                return "";
            }
        }

        const OutputFailedVIES = (checks) => {
            let keys = Object.keys(viesChecks);
            if (keys.length > 0) {
                return <ol>{ keys.map(x => <li>{ x } - { viesChecks[x].reason }</li>) }</ol>
            } else {
                return null;
            }
        }

        const GetSelectedDates = () => {
            if (this.props.selectedDates == null) {
                return this.props.availableDates.map(x => x.getFullYear()).join(", ");
            } else {
                return this.props.selectedDates.join(", ");
            }
        }

        const OutputSkippedVATNumbers = () => {
            if (this.props.skippedVATNumbers.length > 0) {
                return <Typography variant="headline" component="h2">Skipped VIES VAT Numbers: {this.props.skippedVATNumbers.join(", ")}</Typography>
            } else {
                return null;
            }
        }

        let duplicatesCount = duplicates.reduce((acc, val) => {
            let fileDuplicates = Object.keys(val.files).map(x => val.files[x]).reduce((acc, file) => acc + file.rows.length, 0);
            return acc + fileDuplicates;
        }, 0) - duplicates.length;

        if (this.props.selectedFiles.length > 0 && typeof this.props.duplicates != 'undefined') {
            return <div>
                <HeadedPaper title="Summary" className={classes.root + " summary " + classes.paddingHoriz + " " + classes.paddingVert} elevation={1}>
                    <Typography className = {classes.paddingTop} variant="headline" component="h2">
                        Selected files: { this.props.selectedFiles.join(", ") }
                    </Typography>

                    { OutputDate() }
                    { OutputSkippedVATNumbers() }

                    <Typography variant="headline" component="h2">Rows processed: { rowsProcessed }</Typography>
                    <Typography variant="headline" component="h2">VIES checks: { Object.keys(viesChecks).length }</Typography>
                    <Typography variant="headline" component="h2">Duplicates found: { duplicatesCount }</Typography>
                </HeadedPaper>
                <Paper className={classes.root + " summary"} elevation={1}>
                    <VIESChecksTable viesChecks={viesChecks} />
                </Paper>
                <Paper className={classes.root + " summary"} elevation={1}>
                    <DuplicatesTable duplicates={duplicates} />
                </Paper>
            </div>
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return { selectedFiles: state.selectedFiles,
             selectedDates: state.selectedDates,
             selectedCountries: state.selectedCountries,
             error: state.error };
};

export default connect(mapStateToProps, null)(withStyles(styles)(Summary));
