import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from "react-redux";

import { withStyles } from '@material-ui/core/styles';
import HeadedPaper from '../paper/HeadedPaper.jsx';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import { uniq_fast } from "../utils.js";

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,

        boxSizing: "border-box",
    },
    makeFlex: {
        display: "flex"
    },
    flex: {
        flexGrow: 1,
        marginTop: 0,
        paddingRight: theme.spacing.unit * 2
    },
    padding: {
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2
    },
    paddingTop: {
        paddingTop: theme.spacing.unit * 2
    },
    green: {
        color: "green"
    },
    red: {
        color: "red"
    },
    orange: {
        color: "orange"
    },
    bold: {
        fontWeight: "bold"
    },
    title: {
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2
    }
});

const MONTH_NAMES = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

class YearSummary extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { classes, salesData, year, months } = this.props;

        return <HeadedPaper title="Year summary" className={classes.root}>
                    <Typography className={classes.paddingTop} variant="headline" component="h2">Months in data: {months.map(x => MONTH_NAMES[x - 1]).join(", ")}</Typography>
                </HeadedPaper>
    }
}

const mapStateToProps = state => {
    return { salesData: state.salesData };
};

export default connect(mapStateToProps, null)(withStyles(styles)(YearSummary));
