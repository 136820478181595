import CountrySettings from "../country-settings.js";
import Customizer from "./Customizer.js";

function initCountries(country, initData, results) {
    let res = [];
    let c = CountrySettings[country];

    if (c && c.JURIS) {
        if (!results[c.JURIS]) {
            results[c.JURIS] = {};
            Object.assign(results[c.JURIS], initData);
        }

        res.push(results[c.JURIS]);
    }

    if (!results[country]) {
        results[country] = {};
        Object.assign(results[country], initData);
    }

    res.push(results[country]);

    return res;
}

export default class Summary extends Customizer {
    constructor() {
        super();

        this.years = {};
        this.currentYear = {};
        this.currentDepartureCountry = {};
        this.currentArrivalCountries = [];
    }

    visitYear(year) {
        if (!this.years[year])
            this.years[year] = { months: {}, countryInfo: {} };

        this.currentYear = this.years[year];
    }

    visitDepartureCountry(dep) {
        
    }

    visitArrivalCountry(arr) {
        
    }

    visitDate(date) {
        let mon = date.getMonth();

        if (!this.currentYear.months[mon])
            this.currentYear.months[mon] = true;
    }

    initCountry(country) {
        if (!this.currentYear.countryInfo[country])
            this.currentYear.countryInfo[country] = {
                minStockInDate: null,
                minStockOutDate: null,
                minSaleFromDate: null,
                minSaleToDate: null
            };

        return this.currentYear.countryInfo[country];
    }

    visit({ departureCountry, arrivalCountry, type, date, file }) {
        let depCountry = this.initCountry(departureCountry);
        let arrCountry = this.initCountry(arrivalCountry);

        this.currentDepartureCountries = initCountries(departureCountry, { 
            minSaleDate: null, 
            maxSaleDate: null, 
            minStockInDate: null,
            minStockOutDate: null,
            numberOfTransactions: 0,
            numberOfStockMovements: 0 
        }, arrCountry);

        if (type === "INBOUND" || type === "FC_TRANSFER") {
            if (arrCountry.minStockInDate === null || date < arrCountry.minStockInDate) {
                arrCountry.minStockInDate = date;
            }

            if (depCountry.minStockOutDate === null || date < depCountry.minStockOutDate) {
                depCountry.minStockOutDate = date;
            }

            arrCountry[departureCountry].numberOfStockMovements++;

            if (!arrCountry[departureCountry].minStockOutDate || date < arrCountry[departureCountry].minStockOutDate) {
                arrCountry[departureCountry].minStockOutDate = date;
            }
        }

        if (type === "SALE" || type === "REFUND") {
            if (arrCountry.minSaleToDate === null || date < arrCountry.minSaleToDate) {
                arrCountry.minSaleToDate = date;
            }

            if (depCountry.minSaleFromDate === null || date < depCountry.minSaleFromDate) {
                depCountry.minSaleFromDate = date;
            }

            arrCountry[departureCountry].numberOfTransactions++;

            if (!arrCountry[departureCountry].minSaleFromDate || date < arrCountry[departureCountry].minSaleFromDate) {
                arrCountry[departureCountry].minSaleFromDate = date;
            }
        }

        for (var c of this.currentDepartureCountries) {
            /*if (type === "INBOUND" || type === "FC_TRANSFER") {
                if (c.minStockInDate === null || date < c.minStockInDate)
                    c.minStockInDate = date;

                c.numberOfStockMovements++;

                if (!arrCountry.minStockOutDate || date < arrCountry.minStockOutDate) {
                    arrCountry.minStockOutDate = date;
                }
            }*/

            /*if (type === "SALE" || type === "REFUND") {
                if (c.minSaleDate === null || date < c.minSaleDate)
                    c.minSaleDate = date;

                if (c.maxSaleDate === null || date > c.maxSaleDate)
                    c.maxSaleDate = date;

                c.numberOfTransactions++;
            }*/
        }
    }
}
