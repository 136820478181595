import React from 'react';
import ReactDOM from 'react-dom';

import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

export default class MessageBox extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={ this.props.open }
          ContentProps={{
            'aria-describedby': 'message-box',
          }}
          message={<span id="message-box">{ this.props.message }</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={ e => this.props.onClose(e) }
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
    }
}
