import React from 'react';
import ReactDOM from 'react-dom';
import {connect} from "react-redux";

import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';

import {setLoading, hideLoading, setVATRate, updateCountry, updateProcessedSales} from "../../redux/actions.js";
import CountrySettings from "../../country-settings.js";
import {humanDate} from "../utils.js";

import { calculateVAT } from "../../core/vat-functions.js";

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
        boxSizing: "border-box",
    },
    makeFlex: {
        display: "flex"
    },
    flex: {
        flexGrow: 1,
        marginTop: 0,
        paddingRight: theme.spacing.unit * 2
    },
    padding: {
        //paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2
    },
    paddingTop: {
        paddingTop: theme.spacing.unit * 2
    },
    green: {
        color: "green"
    },
    red: {
        color: "red"
    },
    orange: {
        color: "orange"
    },
    bold: {
        fontWeight: "bold"
    },
    title: {
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2
    }
});

function toCommas(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class CountryBox extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            results: [],
            countryDSTs: []
        }
    }

    render() {
        const { data, jurisdictions, classes, departureCountry, country, date, stats, showOnlyExceedingDST, showClose } = this.props;

        function ShowTotals() {
            if (!data)
                return null;

            return Object.keys(data).map(x => {
                let d = data[x];
                return <div class="total">
                            <Typography variant="body2">
                                Gross Sales ({x}): {toCommas(d.gross.toFixed(2))}
                            </Typography>
                            <Typography variant="body2">
                                Net Sales ({x}): {toCommas((d.net + (d.dstNet || 0)).toFixed(2))}
                            </Typography>
                      </div>
            });
        }

        return <Grid className={classes.padding+ " country"} item="item" xs={12} sm={6} md={4} lg={3}>
            <Typography className={classes.title} variant="h5">
                {country.NAME}
            </Typography>
            <Typography variant="body2">
                Number of sales: { stats.numberOfTransactions }
            </Typography>
            <Typography variant="body2">
                Number of stock movements: { stats.numberOfStockMovements }
            </Typography>
            {ShowTotals()}
        </Grid>
    }
}

const mapStateToProps = (state, ownProps) => {
    return {showOnlyExceedingDST: state.showOnlyExceedingDST};
}

export default connect(mapStateToProps, null)(withStyles(styles)(CountryBox));
