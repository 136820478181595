import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from "react-redux";
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const ARVO_STYLE = {
    "fontFamily": "\"Arvo\", \"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
    color: "#333"
}

const THEME_OPTIONS = {
    typography: {
        "fontSize": 15,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500,
        h2: ARVO_STYLE,
        h3: ARVO_STYLE,
        h4: ARVO_STYLE,
        h5: ARVO_STYLE,
        h6: { ...ARVO_STYLE, fontWeight: 600 },
    },
    palette: {
        primary: {
            main: "#024d6b"
        },
        secondary: {
            main: "#c63541"
        }
    }
}

const DARK_THEME_OPTIONS = {
    typography: {
        "fontSize": 15,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500,
        h2: ARVO_STYLE,
        h3: ARVO_STYLE,
        h4: ARVO_STYLE,
        h5: ARVO_STYLE,
        h6: { ...ARVO_STYLE, fontWeight: 600 },
    },
    palette: {
        type: 'dark',
        primary: {
            main: "#024d6b"
        },
        secondary: {
            main: "#c63541"
        }
    }
}

const THEME = createMuiTheme(THEME_OPTIONS);

const DARK_THEME = createMuiTheme(DARK_THEME_OPTIONS);

const mapStateToProps = state => {
    return {darkMode: state.darkMode }
}

export default connect(mapStateToProps, null)(function SVATTheme(props) {
    return (<MuiThemeProvider theme={ props.darkMode ? DARK_THEME : THEME }>
                {props.children}
        </MuiThemeProvider>)
});
