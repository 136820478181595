const { pad, dateStr } = require("../views/utils.js");

const { PromiseCache, PromiseGroup } = require("./promise-cache.js");
const axios = require("axios");
const API_KEY = "ee24c45f5ce969176c335fe8d948e4e6";

const FX_RATES_API = "https://api.macros.simplyvat.com/api/fxrates";

async function getFXRates(date, frm, to) {
    let str = dateStr(date);

    let result = await axios("https://data.fixer.io/api/" + str
    + "?access_key=" + API_KEY
    + "&base=" + frm);

    return result.data.rates[to];
}

async function getFXRatesSuper(source, date, base) {
    let str = dateStr(date);

    let result = await axios("https://data.fixer.io/api/" + str
    + "?access_key=" + API_KEY
    + "&base=" + base);

    return result.data.rates;
}

async function getFXRateLite(date, from, to) {
    let SOURCE = "ECB";
    let AVG = true;
    if (to == "PLN") {
        SOURCE = "NBP";
        AVG = false;
    } else if (to == "CZK") {
        SOURCE = "CNB";
        date = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    } else if (to == "GBP" && date >= new Date(2015, 0, 1)) {
        SOURCE = "HMRC";
    } else if (to != "EUR") {
        SOURCE = "fixer";
        AVG = false;
    }

    let d = dateStr(date);

    if (SOURCE != "fixer") {
        let result = await axios(FX_RATES_API + (AVG ? "/avg" : "") + "/" + SOURCE + "/" + d + "/" + from);

        let rate = result.data.rate;
        if (SOURCE == "HMRC" || SOURCE == "ECB") {
            return 1 / rate;
        } else {
            return rate;
        }
    } else {
        return getFXRates(date, from, to);
    }
}

class FXRatesLookup {
    constructor() {
        this.fxRates = [];
        this.requests = [];
        this.promiseGroup = new PromiseGroup();
    }

    async lookupAverage(date, frm, to) {
        if (frm == to || to.trim() == "" || frm.trim() == "")
            return 1;

        let mnthStart = new Date(date.getFullYear(), date.getMonth(), 1);

        return this.promiseGroup.lookup([mnthStart, frm, to].join("-"), ( resolve, reject ) => {
            getFXRateLite(date, frm, to, true).then( rate => {
                resolve(rate);
            }).catch(e => {
                reject({code: -1, message: "Failed getting fx rate " + frm + " -> " + to + " for month " + pad(date.getMonth() + 1) + "-" + date.getFullYear() + ". Perhaps the currency is not yet available?"});
            });
        });
    }

    async lookup(date, frm, to) {
        if (frm == to || to.trim() == "" || frm.trim() == "")
            return 1;

        return this.promiseGroup.lookup({ average: false, date, frm, to }, ( resolve, reject ) => {
            getFXRateLite(date, frm, to).then( rate => {
                resolve(rate);
            }).catch(e => {
                reject({code: -1, message: "Failed getting fx rate " + frm + " -> " + to + " for date " + date + ". Perhaps the currency is not yet available?"});
            });
        });
    }
}

export {FXRatesLookup};
