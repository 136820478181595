import * as XLSX from 'xlsx';

import webLoadFile from "./WebFileLoader.js";

import CountrySettings from "../country-settings.js";

async function loadXLSX(file) {
    let data = await webLoadFile(file);
    return XLSX.read(data, {
        type: 'binary'
    });
}

export default class ClientInfoLoader {
    constructor() {
        this.clientName = "";
        this.vatRates = [];
        this.costPrices = [];
    }

    getClientName() {
        return this.clientName;
    }

    getVATIDs() {

    }

    getCostPrices() {
        return this.costPrices;
    }

    getVATRates() {
        return this.vatRates;
    }

    serialize() {
        return { clientName: this.getClientName(), vatRates: this.getVATRates() };
    }

    read(file) {
        return new Promise((resolve, err) => {
            loadXLSX(file).then(workbook => {
                let clientInfoSheet = workbook.Sheets["Client Info"];
                let vatRatesSheet = workbook.Sheets["VAT Rates"];
                let costPricesSheet = workbook.Sheets["Cost Prices"];

                let clientInfoRange = XLSX.utils.sheet_to_json(clientInfoSheet);
                let vatRatesRange = XLSX.utils.sheet_to_json(vatRatesSheet);

                for (var row of vatRatesRange) {
                    let headers = Object.keys(row);
                    let sku = row.SKU;
                    for (var i = 3; i < headers.length; i++) {
                        let h = headers[i];
                        if (!this.vatRates[h])
                            this.vatRates[h] = {};

                        this.vatRates[h][sku] = row[h] == "" ? CountrySettings[h].VAT : row[h];
                    }
                }

                this.clientName = clientInfoSheet.B4.v;

                resolve(this);
            });
        });
    }
}
