import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from "react-redux";

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import Country from "./Country.jsx";
import YearSummary from "./YearSummary.jsx";
import CountrySettings from "../../country-settings.js";
import MessageBox from "../../MessageBox.jsx";

const styles = theme => ({
    margins: {
        "&:not(:last-child)>div": {
            marginBottom: theme.spacing.unit * 2,
        }
    }
});

class Viewer extends React.Component {
    constructor(props) {
        super(props);

        //{key, month, value}
        this.state = {
            errMessage: '',
            hasMessage: false,
            message: ''
        }
    }

    message(msg) {
        this.setState({hasMessage: true, message: msg});
    }

    createCountries() {
        const { calculations, classes, stats, years } = this.props;

        return Object.keys(calculations).map(x => CountrySettings[x]).sort((a, b) => a.NAME.localeCompare(b.NAME)).map(x => {
            return <Grid item xs={12} className={classes}>
                        <Country years={years} info={ x } date={ this.props.date } calculations={ calculations[x.CODE] } stats={ stats[x.CODE] } />
                    </Grid>
        });
    }

    tabIndex() {

    }

    render() {
        const { classes } = this.props;
        const cs = this.createCountries();

        const Message = () => {
            if (this.props.error != '') {
                return this.props.error.message;
            } else if (this.props.selectedFiles.length > 0) {
                return "Loading...";
            } else if (this.props.selectedFiles.length == 0) {
                return "Select or upload a file...";
            } else {
                return "Nothing to show.";
            }
        }

        if (cs.length == 0) {
            return (<Grid container
                          spacing={0}
                          direction="column"
                          alignItems="center"
                          justify="center"
                          style={{ minHeight: '70vh' }}
                        >
                          <Grid item xs={3}>
                            <Typography variant="title">{Message()}</Typography>
                          </Grid>
                    </Grid>)
        } else {
            return (<Grid container spacing={0}>
                        <Grid item xs={12} className={this.props.classes.margins}>
                            <YearSummary year={this.props.date} months={this.props.months}/>
                        </Grid>
                        {cs}
                        <MessageBox onClose={e => this.setState({hasMessage: false}) } open={ this.state.hasMessage } message={this.state.message} />
                    </Grid>
                )
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return { selectedFiles: state.selectedFiles,
             error: state.error };
};

export default connect(mapStateToProps, null)(withStyles(styles)(Viewer));
