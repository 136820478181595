import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"

const useStyles = makeStyles(theme => ({
  root: {
    margin: 10
  }
}))

const Section = props => {
  const classes = useStyles()

  const { children } = props

  return (
    <div className={classes.root}>
      <Paper {...props}>{children}</Paper>
    </div>
  )
}

Section.propTypes = {
  children: PropTypes.node.isRequired
}

export default Section
