import React from 'react';
import ReactDOM from 'react-dom';

import ListItem from '@material-ui/core/ListItem';

import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Add from '@material-ui/icons/Add';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';

import TreeItem from '@material-ui/lab/TreeItem';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';


import Switch from '@material-ui/core/Switch';

import LinearProgress from '@material-ui/core/LinearProgress';

import { connect } from "react-redux";
import { selectFile, deselectFile, deleteFile, getFile } from "../redux/actions.js";

import { withStyles } from '@material-ui/core/styles';

import { UploadIconButton } from "./UploadButton";

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  flex: {
    width: "62%",
    flexGrow: 0
  },
  item: {
  },
  folder: {
  },
  itemText: {
      wordBreak: "break-all"
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
  selected: {
    background: "red"
},
});

const useTreeItemStyles = makeStyles((theme) => ({
    group: {
      marginLeft: 0,
      '& $content': {
        paddingLeft: theme.spacing(2),
      },
    },
    expanded: {},
    selected: {
        background: "red"
    },
    label: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
      marginRight: theme.spacing(1),
    },
    labelText: {
      fontWeight: 'inherit',
      flexGrow: 1,
    },
  }));

function TreeItemWithControls(props) {
    const { label, controls, loading } = props;
    const classes = useTreeItemStyles();

    return <TreeItem
        {...props}
        label={
        <div className={classes.labelRoot}>
            <Typography variant="body2" className={classes.labelText}>
            {label}
            { loading && <LinearProgress color="secondary" /> }
            </Typography>
            
            { controls }
            
        </div>
        }
    />
}

class UploadItem extends React.Component {
    constructor(props) {
        super(props);

        this.selected = false;
    }

    select(title, e, i) {
        this.selected = !this.selected;
        if (this.selected) {
            this.props.selectFile(title);
        } else {
            this.props.deselectFile(title);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }

    render() {
        const { fileTree, filename, index, classes } = this.props;

        //if (!filename)
          //  return null;

        const files = fileTree.files;
        const isFolder = !fileTree.isFile;
        const fullPath = isFolder ? fileTree.path : fileTree.key;
        const loading = fileTree.uploading;
        const children = Object.keys(files || {});

        if (this.props.uploading) {
            return <React.Fragment>
                        <ListItem button>
                            <ListItemText className={classes.flex + " " + classes.itemText} primary={filename} />
                        </ListItem>

                        <LinearProgress color="secondary" />
                   </React.Fragment>
        } else {
            /*return <ListItem button filename={index} className={isFolder ? classes.folder : classes.item} onClick={ e => !isFolder && this.select(file.filename, e, index) }>
                <ListItemText className={classes.flex + " " + classes.itemText} primary={file.filename} />

                {!isFolder && <ListItemSecondaryAction>
                    <IconButton onClick={e => this.delete(file.filename, e)} aria-label="Delete">
                        <DeleteIcon />
                    </IconButton>

                    <Switch checked={typeof this.props.selectedFiles.find(x => x == file.filename) != 'undefined' } onChange = { e => this.select(file.filename, e, index) }/>
                </ListItemSecondaryAction> }

                {isFolder && <ListItemSecondaryAction>
                    <IconButton aria-label="Add" onClick={e => this.add(file.filename, e)}>
                        <Add />
                    </IconButton>
                    <IconButton aria-label="Expand" onClick={e => this.expand(file.filename, e)}>
                        <ExpandMore />
                    </IconButton>
                </ListItemSecondaryAction>}
            </ListItem>*/

            if (isFolder) { 
                return <TreeItemWithControls 
                    key={fullPath} 
                    nodeId={fullPath} 
                    label={filename} 
                    controls={[
                        children.length === 0 && <IconButton aria-label="Delete" onClick={this.props.delete(fullPath)} >
                            <DeleteIcon />
                        </IconButton>,
                        <UploadIconButton aria-label="Add" onChange={this.props.upload(filename + "/")}>
                            <Add />
                        </UploadIconButton>
                    ]} 
                    loading={loading}
                >
                    {children.map(file => <UploadItem {...this.props} selected={this.props.selectedFiles.includes(files[file].key)} filename={file} fileTree={files[file]} />)}
                </TreeItemWithControls>
            } else {
                return <TreeItemWithControls 
                    nodeId={fullPath}
                    label={filename}
                    controls={[
                        !loading && <IconButton aria-label="Delete" onClick={this.props.delete(fullPath)} >
                            <DeleteIcon />
                        </IconButton>,
                        !loading && <Switch checked={this.props.selected }/>
                    ]}
                    loading={loading}
                    path={fullPath}
                    inputProps={{
                        path: fullPath
                    }}
                ></TreeItemWithControls>
            }
        }
    }
}

const mapStateToProps = state => {
  return { selectedFiles: state.selectedFiles, files: state.files };
};

const mapDispatchToProps = dispatch => {
  return {
      selectFile: id => dispatch(selectFile(id)),
      deselectFile: id => dispatch(deselectFile(id)),
      deleteFile: id => dispatch(deleteFile(id)),
      getFile: (desc, data) => dispatch(getFile(desc, data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UploadItem));
