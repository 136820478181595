import React from 'react';
import ReactDOM from 'react-dom';

import { connect } from "react-redux";

import CountrySettings from "../country-settings.js";

import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import SortableTableWithHeader from "./tables/SortableTableWithHeader.jsx";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { humanDate } from "./utils.js";

const styles = theme => ({
    root: {
        paddingTop: theme.spacing.unit * 3,
        paddingBottom: theme.spacing.unit * 3,
        paddingLeft: theme.spacing.unit * 3,
        paddingRight: theme.spacing.unit * 3,
        width: "100%"
    },
    padding: {
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2
    },
    paddingTop: {
        paddingTop: theme.spacing.unit * 2,
    },
    paddingBottom: {
        paddingBottom: theme.spacing.unit * 2
    }
});

function Row(props) {
    const { row, index } = props;
    let id = row.id;
    return <TableRow>
                <TableCell>{ id }</TableCell>
                <TableCell>{ row.gross.toFixed(2) + " " + row.currency }</TableCell>
                <TableCell>{ Object.keys(row.files).map(x => <>{ x }<br/></>) }</TableCell>
                <TableCell>{ Object.keys(row.files).map(x => <>{ row.files[x].rows.sort((a, b) => a - b).join(", ") }<br/></>) }</TableCell>
           </TableRow>
}

class DuplicatesTable extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { duplicates } = this.props;

        let paginationOptions = [5, 10, 25, 50, 100];
        paginationOptions = paginationOptions.map(x => x <= duplicates.length ? x : null).filter(x => x != null);
        if (duplicates.length > paginationOptions[paginationOptions.length - 1]) {
            paginationOptions.push(duplicates.length);
        }

        return <SortableTableWithHeader
            headers={
                [ { id: 'id', numeric: false, disablePadding: false, label: 'ID' },
                  { id: 'value', numeric: false, disablePadding: false, label: 'Total gross' },
                  { id: 'files', numeric: false, disablePadding: false, label: 'File(s)' },
                  { id: 'rows', numeric: false, disablePadding: false, label: 'Row(s)' }]
            }
            startOrderBy="id"
            data={duplicates}
            paginate={paginationOptions.length > 0}
            paginationOptions={paginationOptions}
            paginationStartRows={5}
            row={(row, i) => {
                return <Row row={row} index={i} />
            }}
            title="Duplicates"
            elevation={0}
            />
    }
}

const mapStateToProps = (state, ownProps) => {
    return { selectedFiles: state.selectedFiles,
             selectedDates: state.selectedDates,
             selectedCountries: state.selectedCountries,
             error: state.error };
};

export default connect(mapStateToProps, null)(withStyles(styles)(DuplicatesTable));
