import update from 'immutability-helper';
import { deepCopy, combineDates } from "./utils.js";

//import Queue from "../views/queue.js";

const initialState = {
    processedFiles: {},
    ids: [],
    years: [],
    files: {},
    dsts: {},
    stats: {},
    duplicates: [],
    selectedFiles: [],
    availableCountries: [],
    availableDates: [],
    selectedCountries: null,
    dateRanges: null,
    vatRates: {},
    vatRatesMap: {},
    showOnlyExceedingDST: false,
    loading: false,
    loadingProgress: 0,
    salesData: [],
    salesDataProcessed: { total: {} },
    updateIndex: 0,
    totalUpdates: 0,
    fileUpdates: 0,
    error: '',
    skippedVATNumbers: [],
    askingFor: [],
    clientView: false,
    darkMode: false,
    removeDuplicates: true,
    clientInfo: {
        vatRates: {}
    }
};

var processes = [];

const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case "DSTS":
            return { ...state, duplicates: action.duplicates, years: action.years, dsts: action.dsts, stats: action.stats };
        case "CALCULATIONS":
            return {
                ...state, processedFiles: {
                    ...state.processedFiles, [action.file]: {
                        years: action.years,
                        minDate: action.minDate,
                        maxDate: action.maxDate,
                        viesChecks: action.viesChecks,
                        ids: action.ids,
                        rowsProcessed: action.rowsProcessed,
                        duplicates: action.duplicates,
                        calculations: action.calculations
                    }
                }, updateIndex: state.updateIndex + 1
            };
        case "SELECT_FILE":
            return { ...state, selectedFiles: [...state.selectedFiles, action.index] };
        case "SET_AVAILABLE_COUNTRIES":
            return { ...state, availableCountries: [...action.countries] };
        case "SET_AVAILABLE_DATES":
            return { ...state, availableDates: [...action.dates] };
        case "SET_COUNTRIES":
            return { ...state, selectedCountries: [...action.countries] };
        case "SET_DATE_RANGES":
            return { ...state, dateRanges: [...action.dateRanges] };
        case "DESELECT_FILE":
            return { ...state, selectedFiles: state.selectedFiles.filter(f => f != action.index) };
        case "DELETE_FILE":
            return {
                ...state, files: Object.keys(state.files).reduce((acc, val) => {
                    if (val != action.desc) {
                        acc[val] = state.files[val];
                    }

                    return acc;
                }, {}), processedFiles: Object.keys(state.processedFiles).reduce((acc, val) => {
                    if (val != action.desc) {
                        acc[val] = state.processedFiles[val];
                    }

                    return acc;
                }, {})
            };
        case "GET_FILE":
            return { ...state, files: { ...state.files, [action.file.key]: action.file } };
        case "CUSTOM_FILE_DATA":
            var file = state.files.find(x => x.desc == action.desc);
            var id = state.files.findIndex(x => x.desc == action.desc);

            if (file) {
                var s = update(state, { files: { [id]: { customData: { $merge: action.data } } } });
                s.fileUpdates++;
                return s;
            } else if (!file) {
                file = { desc: action.desc, customData: action.data || {} };
                return { ...state, files: [...state.files, file] };
            }
            return { ...state };
        case "SET_ONLY_EXCEEDING_DSTS":
            return { ...state, showOnlyExceedingDST: action.flag };
        case "SET_LOADING":
            return { ...state, loading: true, loadingProgress: action.progress };
        case "HIDE_LOADING":
            return { ...state, loading: false };
        case "CLEAR_SALES_DATA_TOTAL":
            return { ...state, salesDataProcessed: { total: {} } };
        case "SET_VAT_RATE":
            let rates = state.vatRates;
            return { ...state, vatRates: { ...rates, [action.country]: action.rate } };
        case "PROCESS_RESULTS":
            return { ...state, salesData: action.results };
        case "PROCESS_COUNTRIES":
            return { ...state, updateIndex: state.updateIndex + 1 };
        case "ERROR":
            return { ...state, error: action.error };
        case "SKIP_VAT_NUMBER":
            return { ...state, skippedVATNumbers: [...state.skippedVATNumbers, action.vatNumber] }
        case "ASK_FOR":
            return { ...state, askingFor: [...state.askingFor, action.data] };
        case "SHIFT_ASK_FOR":
            let requests = [...state.askingFor];
            requests.shift();
            return { ...state, askingFor: requests };
        case "SET_CLIENT_VIEW":
            return { ...state, clientView: action.flag };
        case "TOGGLE_DARK_MODE":
            return { ...state, darkMode: !state.darkMode };
        case "SET_REMOVE_DUPLICATES":
            return { ...state, removeDuplicates: action.flag };
        case "SET_CLIENT_INFO":
            return { ...state, clientInfo: action.clientInfo };
        default:
            return state;
    }
};
export default rootReducer;
