import React from 'react';
import ReactDOM from 'react-dom';
import axios from "axios";
import update from 'immutability-helper';
import { connect } from "react-redux";

import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';

import Box from '@material-ui/core/Box';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import {getFile, deselectFile, selectFile, deleteFile } from "../redux/actions.js";

import UploadList from "./UploadList.jsx";
import UploadButton from "./UploadButton.jsx";

import DragAndDrop from "./DragAndDrop.jsx";

import NewDirectoryModal from "../views/modals/NewDirectoryModal";


const styles = theme => ({
    makeFlex: {
        flexGrow: 1,
        overflow: "auto",
        /* width */
        "&::-webkit-scrollbar": {
          width: 10
      },

        /* Track */
        "&::-webkit-scrollbar-track": {
          background: "#f1f1f1"
      },

        /* Handle */
        "&::-webkit-scrollbar-thumb": {
          background: "#888"
      },

        /* Handle on hover */
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#555"
        }
    },
    flex: {
        flexGrow: 1,
        flexBasis: 0
    },
    bottomLeft: {
        position: "absolute",
        left: 0,
        bottom: 0,
    },
    drawerPaper: {
        overflow: "initial",
        paddingTop: "64px",
        width: '25%'
    }
});

function filesToTree(files, currentTree = { path: "/", files: {}}) {
    for (let f of files) {
        //console.log(f);
        let obj = currentTree.files;

        let parts = f.key.split("/");
        let last = parts.pop();

        let inFolder = parts.length > 0;
        
        if (inFolder) {
            for (let p of parts) {
                obj[p] = {...(obj[p] || {
                    path: f.key,
                    files: {}
                })};
                obj = obj[p].files;
            }
        }

        if (last && !last.endsWith("/"))
            obj[last] = {...f, isFile: true};
    }

    return currentTree;
}

class Uploader extends React.Component {
    constructor(props) {
        super(props);

        this.uploadedFiles = [];

        this.state = {
            uploads: [],
            selected: [],
            files: [],
            uploadingFiles: {},
            fileTree: {
                path: "/",
                files: {}
            },
            newDirectoryModalOpen: false,
            search: ""
        };

        this.requestFiles();
    }

    requestFiles() {
        return this.props.axiosInstance.get('/api/uploads').then((uploads) => {
            for (var u of uploads.data) {
                this.props.getFile(u);
            }

            this.setState({
                fileTree: filesToTree(uploads.data)
            });
        });
    }

    search = e => {
        this.setState({
            fileTree: filesToTree(Object.keys(this.props.files).filter(x => x.includes(e.target.value)).map(x => this.props.files[x])),
            search: e.target.value
        });
    }

    async setFiles(prefix, files) {
        if (files.length == 0)
            return;

        let arr = [];
        for (let f of files)
            arr.push({ key: prefix + f.name, uploading: true });

        this.setState({
            fileTree: filesToTree(arr, {...this.state.fileTree})
        });

        try {
            for (let f of files) {
                let response = await fetch(process.env.REACT_APP_API_URL + "/api/upload/" + encodeURIComponent(prefix + f.name), {
                    method: "PUT",
                    body: f
                });

                this.requestFiles();
            }
        } catch (err) {
            console.error(err);
        }
    }

    delete = title => async e => {
        let response = await fetch(process.env.REACT_APP_API_URL + '/api/delete/' + encodeURIComponent(title), {
            method: "DELETE"
        });

        console.log(title);

        if (response.status === 200) {
            let i = this.props.selectedFiles.find(i => i == title);

            if (typeof i !== 'undefined') {
                this.props.deselectFile(i);
            }

            this.props.deleteFile(title);

            this.setState({
                fileTree: filesToTree(Object.keys(this.props.files).map(x => this.props.files[x]))
            })
        } else {
            alert("Problem deleting file: " + await response.text());
        }
    }

    newFolder(e) {
        this.setState({
            newDirectoryModalOpen: true
        });
        
    }

    upload = prefix => e => {
        return this.setFiles(prefix, e.target.files);
    }

    render() {
        const {classes} = this.props;
        return (<React.Fragment>
                  <Drawer variant="permanent"
                      classes={{
                          paper: classes.drawerPaper,
                      }}
                      className="no-print"
                      open={ true } >
                        <div className={classes.makeFlex}>
                            <Box pl={2} pr={2}>
                                <TextField fullWidth
                                    id="search"
                                    name="search"
                                    label="Search"
                                    onChange={this.search}
                                    value={this.state.search}
                                />
                            </Box>
                            <DragAndDrop handleDrop={dropped => this.upload("")({target: {
                                files: dropped
                            }})}>
                                <UploadList axiosInstance={this.props.axiosInstance} onUpdate={selected => {
                                    this.setState({selected});
                                }} uploading={ this.state.uploadingFiles } upload={this.upload} delete={this.delete} fileTree={this.state.fileTree}/>
                            </DragAndDrop>
                        </div>
                        <Button color="primary" variant="contained" onClick={e => this.newFolder(e)}>New folder</Button>
                        <UploadButton onChange={ this.upload("") } />
                   </Drawer>
                   <NewDirectoryModal open={this.state.newDirectoryModalOpen} handleClose={
                       values => {
                            this.setState({
                                newDirectoryModalOpen: false
                            });

                            if (values) {
                                fetch(process.env.REACT_APP_API_URL + "/api/upload/" + encodeURIComponent(values.directoryName + "/"), {
                                    method: "PUT",
                                    body: ""
                                }).then(() => {
                                    this.requestFiles();
                                });
                            }
                       }
                    }/>
                </React.Fragment>);
    }
}

const mapStateToProps = state => {
  return { fileDescs: state.fileDescs, selectedFiles: state.selectedFiles, files: state.files };
};

const mapDispatchToProps = dispatch => {
  return {
    getFile: (desc, data) => dispatch(getFile(desc, data)),
    selectFile: id => dispatch(selectFile(id)),
    deselectFile: id => dispatch(deselectFile(id)),
    deleteFile: id => dispatch(deleteFile(id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Uploader));
