import React, { useEffect } from "react"
import { useOktaAuth } from "@okta/okta-react"
import CircularLoader from "./common/CircularLoader"

const Logout = () => {
  const { authState, authService } = useOktaAuth()

  useEffect(() => {
    if (authState.isPending || authState.isAuthenticated) {
      localStorage.removeItem("aws-credentials")
      authService.logout("/login") // TODO: Make this a constant
    }
  }, [])

  return <CircularLoader open transitionDuration={0} color="primary" />
}

export default Logout
