import React from 'react';
import ReactDOM from 'react-dom';

import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';

export default class ErrorBox extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={ this.props.open }
          ContentProps={{
            'aria-describedby': 'error-box',
          }}
          message={<span id="error-box"><h3>Error Code: { this.props.error.code }</h3> { this.props.error.message }</span>}
          action={this.props.actions}
        />
    }
}
