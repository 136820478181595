import React from 'react';
import ReactDOM from 'react-dom';

import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';

import TextField from '@material-ui/core/TextField';

import CountrySettings from "../country-settings.js";

import { connect } from "react-redux";

import { setOnlyExceedingDSTs } from "../redux/actions.js";

const styles = theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 2
  },
  formControl: {
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    display: "block",
  },
  typography: {
    padding: theme.spacing.unit * 2,
  }
});

class Settings extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { classes } = this.props;

        const { anchorEl, open } = this.props;
        const id = open ? 'simple-popper' : null;

        return (<Popper className={classes.root} id={id} open={open} anchorEl={anchorEl} transition>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={150}>
              <Paper>
                <Typography variant="headline" className={classes.typography}>Settings</Typography>
                  <FormControl component="fieldset" className={classes.formControl}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                                checked = {this.props.showOnlyExceedingDST}
                                onChange = {e => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    this.props.setOnlyExceedingDSTs(e.target.checked);

                                }}
                            />
                          }
                          label="Show only sales exceeding DST"
                        />
                    </FormGroup>
                </FormControl>
              </Paper>
            </Fade>
          )}
      </Popper>)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setOnlyExceedingDSTs: flag => dispatch(setOnlyExceedingDSTs(flag))
    };
};

const mapStateToProps = state => {
    return { showOnlyExceedingDST: state.showOnlyExceedingDST };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Settings))
