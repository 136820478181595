import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from "react-redux";

import List from '@material-ui/core/List';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';

import { selectFile, deselectFile, deleteFile, getFile, setLoading, hideLoading } from "../redux/actions.js";

import UploadItem from "./UploadItem.jsx";

import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';

const styles = theme => ({
    list: {
        overflow: "initial",
    }
});

class UploadList extends React.Component {
    constructor(props) {
        super(props);
    }

    createList() {
        const { fileTree } = this.props;

        let keys = Object.keys(fileTree.files);

        keys = keys.sort((a, b) => {
            return a.localeCompare(b);
        });

        return keys.map((key, i) => {
            return <UploadItem 
                key={key} 
                upload={this.props.upload} 
                delete={this.props.delete} 
                filename={key} 
                fileTree={fileTree.files[key]} 
                fullPath={fileTree.path} 
                index={i} 
                axiosInstance={this.props.axiosInstance}
                selected={this.props.selectedFiles.includes(key)}
            />
        });
    }
    
    render() {
        const { classes } = this.props;

        return <TreeView
            className={classes.root}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            onNodeSelect={ (e, v) => {
                if (v.endsWith("/"))
                    return;

                const selected = this.props.selectedFiles.includes(v);
                if (!selected)
                    this.props.selectFile(v);
                else
                    this.props.deselectFile(v);
                } 
            }>
            {this.createList()}
        </TreeView>
    }
}

const mapStateToProps = state => {
  return { selectedFiles: state.selectedFiles, files: state.files };
};

const mapDispatchToProps = dispatch => {
  return {
      selectFile: id => dispatch(selectFile(id)),
      deselectFile: id => dispatch(deselectFile(id)),
      deleteFile: id => dispatch(deleteFile(id)),
      getFile: (desc, data) => dispatch(getFile(desc, data)),
      setLoading: progress => dispatch(setLoading(progress)),
      hideLoading: () => dispatch(hideLoading())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UploadList));
