import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import LoginForm from "./LoginForm";
import { useOktaAuth } from "@okta/okta-react";
import CircularLoader from "../common/CircularLoader";

const Login = ({ issuer, redirectPath }) => {
  const { authState } = useOktaAuth();

  if (authState.isPending)
    return <CircularLoader open color="primary" transitionDuration={0} />;

  return authState.isAuthenticated ? (
    <Redirect to={{ pathname: redirectPath }} />
  ) : (
    <LoginForm issuer={issuer} />
  );
};

Login.propTypes = {
  issuer: PropTypes.string.isRequired,
};

export default Login;
