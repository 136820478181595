import React from 'react';
import ReactDOM from 'react-dom';

import Button from '@material-ui/core/Button';

function buildFileSelector(){
  const fileSelector = document.createElement('input');
  fileSelector.setAttribute('type', 'file');
  fileSelector.setAttribute('accept', '.json,.csv');
  return fileSelector;
}

export default class OpenFileButton extends React.Component {
    constructor(props) {
        super(props);
    }

    buildFileSelector(){
        const fileSelector = document.createElement('input');
        fileSelector.setAttribute('type', 'file');
        fileSelector.setAttribute('accept', this.props.fileTypes);
        fileSelector.addEventListener('change', this.upload.bind(this));
        return fileSelector;
    }

    upload(e) {
        this.props.onChange(e);
    }

    render() {
        this.btnRef = React.createRef();
        return <Button { ...this.props } onClick={ e => this.buildFileSelector(e).click()}>
         {this.props.children}
         </Button>
    }
}
