function isAsync(fn) {
   return fn.constructor.name === 'AsyncFunction';
}

async function retry(func, times, c = 0) {
    try {
        let res = isAsync(func) ? await func() : new Promise(func);
        return res;
    } catch (e) {
        console.log("fail " + c);
        if (c == times)
            throw e.message ? e.message : e;

        return retry(func, times, c + 1);
    }
}

class PromiseGroup {
    constructor(cacheCheckInterval) {
        this.promises = {};
        this.cacheCheckInterval = cacheCheckInterval;
    }

    lookup(query, func, options = {retries: 0 }) {
        let d = new Date();

        if (typeof this.cacheCheckInterval != 'undefined' && (!this.nextCacheCheck || d > this.nextCacheCheck)) {
            this.promises = this.promises.filter(x => x.expiry > d);
            this.nextCacheCheck = new Date(Date.now() + this.cacheCheckInterval);
        }

        let found = this.promises[query];

        if (found && (!found.expiry || found.expiry > d)) {
            return found.promise;
        } else {
            let promise = retry(func, options.retries || 0);

            this.promises[query] = options.expiryDate ? {promise, expiry: options.expiryDate} : {promise};

            promise.catch((promise => () => {
                delete this.promises[query];
            })(promise));

            return promise;
        }
    }

    clear() {
        this.promises = {};
    }

    all() {
        return Promise.all(Object.keys(this.promises).map(x => this.promises[x]));
    }
}

class PromiseCache {
    constructor(db, cacheCheckInterval) {
        this.db = db;
        this.cache = [];
        this.queryCache = [];
        this.cacheCheckInterval = cacheCheckInterval;
        this.cleanup();
    }

    cleanup() {
        let now = new Date();

        if (typeof this.cacheCheckInterval != 'undefined' && (!this.nextCacheCheck || now > this.nextCacheCheck)) {
            this.db.deleteMany({"expiryDate": { $lte: now }});
            this.nextCacheCheck = new Date(Date.now() + this.cacheCheckInterval);
        }
    }

    lookup(query) {
        this.cleanup();

        let promise = new Promise( (resolve, reject) => {
            if (typeof this.cacheCheckInterval != 'undefined') {
                query.expiryDate = { $gt: new Date() };
            }

            this.db.find(query).toArray(function(err, result) {
                if (err) throw err;

                if (result.length > 0) {
                    resolve(result);
                } else {
                    reject();
                }
            });
        });

        this.cache.push({query, promise});
        return promise;
    }

    insert(obj, expiryDate) {
        if (typeof expiryDate != 'undefined') {
            obj.expiryDate = expiryDate;
        }

        return new Promise( (resolve, reject) => {
            this.db.insertOne(obj, function(err, res) {
                if (err) throw err;

                resolve(res);
            });
        });
    }
}

export {PromiseCache, PromiseGroup, retry};
