async function runFunc(customizers, func) {
    return (await Promise.all(customizers.map(func)));
}

export default async function process({ dataFiles, customizers }) {
    let shouldSkip = false;

    for (let file in dataFiles) {
        shouldSkip = (await Promise.all(customizers.map(customizer => customizer.visitFile(file)))).filter(x => !x).length == 0;
        if (shouldSkip)
            continue;

        let fileObj = dataFiles[file].calculations;
        for (let year in fileObj) {
            shouldSkip = (await Promise.all(customizers.map(customizer => customizer.visitYear(year)))).filter(x => !x).length == 0;
            if (shouldSkip)
                continue;

            let yearObj = fileObj[year];
            for (let depCountry in yearObj) {
                shouldSkip = (await Promise.all(customizers.map(customizer => customizer.visitDepartureCountry(depCountry)))).filter(x => !x).length == 0;
                if (shouldSkip)
                    continue;

                let depCountryObj = yearObj[depCountry];
                for (let arrCountry in depCountryObj) {
                    shouldSkip = (await Promise.all(customizers.map(customizer => customizer.visitArrivalCountry(arrCountry)))).filter(x => !x).length == 0;
                    if (shouldSkip)
                        continue;

                    let arrCountryObj = depCountryObj[arrCountry];
                    for (let date of Object.keys(arrCountryObj).map(x => new Date(x)).sort((a, b) => a.getTime() - b.getTime())) {
                        shouldSkip = (await Promise.all(customizers.map(customizer => customizer.visitDate(date)))).filter(x => !x).length == 0;
                        if (shouldSkip)
                            continue;

                        let dateStrObj = arrCountryObj[date];
                        for (let currency in dateStrObj) {
                            let currencyObj = dateStrObj[currency];
                            shouldSkip = (await Promise.all(customizers.map(customizer => customizer.visitCurrency(currency)))).filter(x => !x).length == 0;
                            if (shouldSkip)
                                continue;

                            for (let id in currencyObj) {
                                let idObj = currencyObj[id];

                                let gross = idObj.gross;
                                let type = idObj.type;
                                let rows = idObj.rows;
                                let sku = idObj.sku;

                                shouldSkip = (await Promise.all(customizers.map(customizer => customizer.visitId(id)))).filter(x => !x).length == 0;
                                if (shouldSkip)
                                    continue;

                                await Promise.all(customizers.map(customizer => customizer.visit({ file, year, departureCountry: depCountry, arrivalCountry: arrCountry, date, currency, id, gross, rows, sku, type })));

                                await Promise.all(customizers.map(customizer => customizer.visitEndId(id)));
                            }
                            await Promise.all(customizers.map(customizer => customizer.visitEndCurrency(currency)));
                        }
                        await Promise.all(customizers.map(customizer => customizer.visitEndDate(date)));
                    }
                    await Promise.all(customizers.map(customizer => customizer.visitEndArrivalCountry(arrCountry)));
                }
                await Promise.all(customizers.map(customizer => customizer.visitEndDepartureCountry(depCountry)));
            }
            await Promise.all(customizers.map(customizer => customizer.visitEndYear(year)));
        }
        await Promise.all(customizers.map(customizer => customizer.visitEndFile(file)));
    }
    await Promise.all(customizers.map(customizer => customizer.end()));
}
