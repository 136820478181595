import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Provider } from "react-redux";
import axios from "axios";
import "./sass/styles.css";
import "./sass/print.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import store from "./redux/store.js";
import Uploader from "./uploader/Uploader.jsx";
import Toolbar from "./views/toolbar.jsx";
import DefaultView from "./views/DefaultView.jsx";
import SVATTheme from "./theme/SVATTheme.jsx";
import { Route, Switch, useHistory } from "react-router-dom";
import { Security, LoginCallback, SecureRoute } from "@okta/okta-react";
import { SnackbarProvider } from "notistack";
import { SnackbarUtilsConfigurator } from "./common/SnackbarUtils";
import HistoryRouter from "./HistoryRouter";
import Login from "./login/Login.jsx";
import Logout from "./Logout";

window.store = store;

let axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const config = {
  issuer: process.env.REACT_APP_OKTA_URL + "/oauth2/ausp5xli36I2u4ov10h7",
  redirectUri: window.location.origin + "/implicit/callback",
  pkce: true,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
};

const MainPage = (props) => {
  return (
    <React.Fragment>
      <Toolbar />
      <Uploader axiosInstance={axiosInstance} />
      <DefaultView axiosInstance={axiosInstance} />
    </React.Fragment>
  );
};

const Routes = () => {
  const history = useHistory();

  return (
    <Security
      issuer={config.issuer}
      clientId={config.clientId}
      redirectUri={config.redirectUri}
      pkce={true}
      onAuthRequired={() => history.push("/login")}
    >
      <Switch>
        <SecureRoute exact path="/" component={MainPage} />
        <SecureRoute path="/logout" component={Logout} />
        <Route
          path="/login"
          render={(props) => <Login issuer={config.issuer} {...props} />}
        />
        {/* <Route path="/register" component={RegisterPage} /> */}
        <Route path="/implicit/callback" component={LoginCallback} />
      </Switch>
    </Security>
  );
};

const App = () => {
  return (
    <Provider store={store}>
      <SVATTheme>
        <CssBaseline />
        <SnackbarProvider
          preventDuplicate
          maxSnack={7}
          autoHideDuration={10000}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <SnackbarUtilsConfigurator />

          <HistoryRouter>
            <Routes />
          </HistoryRouter>
        </SnackbarProvider>
      </SVATTheme>
    </Provider>
  );
};

export default App;
