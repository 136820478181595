const CSVtoArray = require("./CSVtoArray.js");

function interpret(string) {
    let rows = [];
    let lines = string.split("\r\n");

    if (lines.length != 0) {
        let headers = lines[0].split("\t");

        for (var i = 1; i < lines.length; i++)
            if (lines[i].length > 0)
                rows.push(lines[i].split("\t"));

        return { headers, rows };
    }
}

export { interpret };
