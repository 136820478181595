import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from "react-redux";

import { getFile, setAvailableCountries, setAvailableDates, setDates, setCountries, setLoading, hideLoading, queueProcess, processFiles, setError, skipVATNumber } from "../../redux/actions.js";

import ErrorBox from "../../ErrorBox.jsx";
import ERRORS from "../../errors.js";

import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
    paper: {
        position: 'absolute',
        width: "50%",
        height: "50%",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    }
});


class EbayDataModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            departureCountry: ""
        }
    }

    onChange(e) {
        this.setState({departureCountry: e.target.value});
    }

    render() {
        const {classes} = this.props;

        return <Dialog
                  open={this.props.open}
                  onClose={this.props.handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Please enter the departure country for this sale data
                    </DialogContentText>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="departure-country"
                      label="Departure Country"
                      type="text"
                      onKeyDown={e => e.key ==='Enter' && this.props.onDone({...this.state})}
                      fullWidth
                      onChange={this.onChange.bind(this)}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={e => this.props.onCancel({...this.state})} color="primary">
                      Cancel
                    </Button>
                    <Button onClick={e => this.props.onDone({...this.state})} color="primary" autoFocus>
                      Done
                    </Button>
                  </DialogActions>
                </Dialog>
    }
}

export default withStyles(styles)(EbayDataModal);
