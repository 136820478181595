import ERRORS from "../errors.js";
import CountrySettings from "../country-settings.js";

export default function FileProcessor(file, dateRanges, skippedVATNumbers, fxRateLookup, idsCache) {
    let headers = file.data.headers;
    let workerSrc = "";

    workerSrc = "/workers/worker.js";

    return new Promise((resolve, err) => {
        let worker = new Worker(workerSrc);

        worker.onmessage = e => {
            if (e.data.request) {
                let request = e.data.request;
                let query = request.query;

                query.frm = query.frm == "" ? query.to : query.frm;

                fxRateLookup.lookupAverage(query.date, query.frm, query.to).then( fx => {
                    worker.postMessage({response: {id: request.id, data: fx}});
                });
            } else {
                let response = e.data;
                if (response.err) {
                    err(response.message);

                    worker.terminate();
                } else {
                    let results = response;

                    worker.terminate();
                    resolve(results);
                }
            }
        }

        worker.postMessage({ data: file.data, dateRanges, skippedVATNumbers, idsCache, countryData: { ...CountrySettings, getDST: undefined } });
    });
}
