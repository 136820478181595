import { call, put, takeEvery, takeLatest, all, select } from 'redux-saga/effects';
import { getFile, setAvailableCountries, setAvailableDates,
         setDates, setCountries, setLoading,
         hideLoading, queueProcess, setError, askFor, clearSalesDataTotal } from "./actions.js";

import FileProcessor from "./file-processor.js";
import axios from "axios";
import processCore from "../core/Processor.js";
import DSTCustomizer from "../core/DSTs.js";
import Summary from "../core/Summary.js";

const { FXRatesLookup } = require("../server/fx-rates-lookup.js");
const fxRateLookup = new FXRatesLookup();


var axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

const delay = ms => new Promise(res => setTimeout(res, ms));

export function findCalculations(file) {
    return select((state, f) => state.processedFiles[file], file);
}

export function* loadFile({file}) {
    let signedUrl = yield call(file => axiosInstance.get('/api/upload/' + encodeURIComponent(file)), file);

    let data = yield call(signedUrl => fetch(signedUrl.data), signedUrl);

    let fileType = file.split(".");
    fileType = fileType[fileType.length - 1];

    const { interpret } = require("../server/" + fileType + ".js");

    if (!data.ok && data.status !== 200) {
        throw `Retrieved file '${file}' unsuccessfully, or file is blank!`;
    }

    data = yield call(data => data.text(), data);

    return yield {data: interpret(data)};
}

export function* processFile({file, dateRanges, skippedVATNumbers, idsCache}) {
    let found = yield findCalculations(file);

    if (!found) {
        let fileData = yield loadFile({file});

        let res = yield call((file, dateRanges, skippedVATNumbers, fxRateLookup, idsCache) => FileProcessor(file, dateRanges, skippedVATNumbers, fxRateLookup, idsCache), fileData, dateRanges, skippedVATNumbers, fxRateLookup, idsCache);

        const { minDate, maxDate, results, viesChecks, rowsProcessed, ids, duplicates } = res;

        let years = Object.keys(results);

        yield put({ type: 'CALCULATIONS', file, ids, rowsProcessed, minDate, maxDate, years, duplicates, viesChecks, calculations: results });
    }
}

export function* processData({selectedFiles, processedFiles}) {
    let allFilesLoaded = selectedFiles.filter(x => processedFiles[x]).length === selectedFiles.length;

    if (allFilesLoaded) {
        let calcs = {};
        let f = {};

        for (var i of selectedFiles) {
            f[i] = processedFiles[i];
        }

        let vatRates = yield select(state => state.vatRates);
        let clientInfo = yield select(state => state.clientInfo);
        let removeDuplicates = yield select(state => state.removeDuplicates);

        let dsts = new DSTCustomizer(fxRateLookup, vatRates, removeDuplicates, clientInfo);
        let summary = new Summary();

        yield call((dsts, summary) => processCore({ dataFiles: f, customizers: [dsts, summary] }), dsts, summary);

        yield put({ type: 'DSTS', duplicates: dsts.duplicates, years: Object.keys(dsts.results), dsts: dsts.results, stats: summary.years });
    }
}

export function* processFiles({files, dateRanges, skippedVATNumbers, countryData}) {
    try {
        let idsCache = [];
        let isLoading = false;

        yield put(setLoading(-1));

        yield delay(1000);

        for (var x of files) {
            yield processFile({file: x, dateRanges, skippedVATNumbers, idsCache, countryData});
        }

        let processedFiles = yield select(state => state.processedFiles);

        //do clever stuff
        yield processData({selectedFiles: files, processedFiles});

        yield put({type: 'FINISHED_PROCESSING'});
    } catch (e) {
        console.log("ERROR", e);
        if (typeof e.code != "undefined")
            yield put(setError(e));
        else if (e.askFor) {
            yield put(askFor(e.data));
        } else {
            yield put(setError({code: -1, message: e.message ? e.message : e}));
        }
    }

    yield put(hideLoading());
}

export default function* processFilesAsync() {
    yield takeLatest('PROCESS_FILE', processFile);
    yield takeLatest('PROCESS_FILES', processFiles);
    //yield takeLatest('MAKE_TOTAL', makeTotal);
}
