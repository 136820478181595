import React from 'react';
import ReactDOM from 'react-dom';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

function CustomTableCell(props) {
    return <TableCell className={props.classes} style={{ fontWeight: "bold" }}>{ props.children }</TableCell>
}

export default withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontSize: 16
    },
    body: {
        fontSize: 15,
    }
}))(TableCell);
