import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import View from "./viewer/View.jsx";
import ErrorHandler from "./ErrorHandler.jsx";
import EbayDataModal from "./modals/EbayDataModal.jsx";

import { getFile, customFileData, setAvailableCountries, setAvailableDates, setCountries, setLoading, hideLoading, processFile, processFiles, setError, skipVATNumber, askFor, shiftAskFor } from "../redux/actions.js";

import { humanDate } from "./utils.js";

import ERRORS from "../errors.js";

import YearTabs from "./YearTabs.jsx";
import ClientYearTabs from "./client/ClientYearTabs.jsx";
import TabContainers from "./TabContainers.jsx";

import { combineResults } from "../redux/utils.js";

var merge = require('lodash.merge');
var mergeWith = require('lodash.mergewith');

const styles = theme => ({
    root: {
        flexGrow: 1,
        paddingTop: theme.mixins.toolbar.minHeight + 7,
        paddingLeft: "25%",
        height: "100%",
        flexGrow: 1,
        position: "relative"
    },
    tabs: {
        position: "sticky",
        top: theme.mixins.toolbar.minHeight + 7,
        zIndex: theme.zIndex.drawer + 1,
    }
});

class DefaultView extends React.Component {
    constructor() {
        super();
        this.state = {
            tabKey: "TOTAL"
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { selectedFiles, selectedCountries, selectedDates, salesData, salesDataProcessed, updateIndex, skippedVATNumbers, fileUpdates, removeDuplicates, vatRates, clientInfo } = this.props;

        let diffCountries = prevProps.selectedCountries ? prevProps.selectedCountries.join(",") != selectedCountries.join(",") : selectedCountries != null;
        let diffDates = prevProps.selectedDates ? prevProps.selectedDates.join(",") != selectedDates.join(",") : selectedDates != null;
        let diffSelected = selectedFiles.length != prevProps.selectedFiles.length;
        let diffSkippedVATNumbers = skippedVATNumbers.length != prevProps.skippedVATNumbers.length;
        let metasUpdated = prevProps.fileUpdates != fileUpdates;
        let diffRemoveDuplicates = prevProps.removeDuplicates != removeDuplicates;
        let diffVATRates = JSON.stringify(prevProps.vatRates) != JSON.stringify(vatRates);
        let diffClientInfo = JSON.stringify(prevProps.clientInfo) != JSON.stringify(clientInfo);

        if (diffCountries || diffSelected || diffDates || diffSkippedVATNumbers || metasUpdated || diffRemoveDuplicates || diffVATRates || diffClientInfo) {
            this.startProcessing();
        }

        if (updateIndex != prevProps.updateIndex) {
            this.finishProcessing(salesData, salesDataProcessed);
        }
    }

    startProcessing() {
        this.setState({ errMessage: '', hasMessage: false, countryData: [] });

        this.props.processFiles(this.props.selectedFiles, null, this.props.skippedVATNumbers);
        /*this.props.processFiles(this.props.selectedFiles,
                                this.props.selectedDates,
                                this.props.selectedCountries,
                                this.props.vatRates,
                                this.props.skippedVATNumbers);*/
    }

    finishProcessing(results, countries) {
        /*if (this.props.selectedCountries.indexOf(true) == -1) {
            let cs = this.props.selectedCountries.filter(x => this.props.availableCountries.indexOf(x) != -1);
            this.props.setCountries(cs);
        }

        this.setState({processTotal: combineResults(this.props.availableDates, results.data),
                       countryData: countries,
                       dataLen: results.data.length,
                       duplicates: results.duplicates,
                       viesChecksFailed: results.viesChecksFailed,
                       firstSale: results.firstSale});*/
    }

    handleClose(event, type, error) {
        if (type == "message") {
            this.setState({ hasMessage: false });
        } else if (type == "error") {
            this.setState({ errMessage: '' });
        } else if (type == "retry") {
            this.startProcessing();
        } else if (type == "skip") {
            this.props.skipVATNumber({ type: "single", vatId: error.vatId });
        } else if (type.indexOf("skip-all-") != -1) {
            this.props.skipVATNumber({ type: "multiple", country: type.replace("skip-all-", "") });
        } else if (type == "skip-all") {
            this.props.skipVATNumber({ type: "all" });
        }
        this.props.setError('');
    };

    render() {
        const { classes, salesDataProcessed, selectedFiles, salesData, skippedVATNumbers, clientView, processedFiles, years } = this.props;
        let { duplicates } = this.props;
        const { tabKey } = this.state;

        let calcs = {};
        let ids = [];
        let viesChecks = {};

        function customizer(a, b) {
            if (typeof a === 'number' && typeof b === 'number') {
                return a + b;
            }
        }

        let minDate = null;
        let rowsProcessed = 0;

        for (var x of selectedFiles) {
            if (processedFiles[x]) {
                if (!minDate) {
                    minDate = processedFiles[x].minDate;
                } else if (processedFiles[x].minDate < minDate) {
                    minDate = processedFiles[x].minDate;
                }

                let checks = Object.keys(processedFiles[x].viesChecks).reduce((acc, id) => {
                    acc[id] = { ...processedFiles[x].viesChecks[id], id, files: { [x]: { rows: processedFiles[x].viesChecks[id].rows } } };
                    return acc;
                }, {});

                viesChecks = merge(viesChecks, checks);

                //duplicates[x] = processedFiles[x].duplicates;
                rowsProcessed += processedFiles[x].rowsProcessed;
            } else {
                minDate = null;
                calcs = {};
                viesChecks = {};
                duplicates = [];
                rowsProcessed = 0;
                break;
            }
        }

        viesChecks = Object.keys(viesChecks).map(x => viesChecks[x]);

        const CreateErrorBox = error => {
            return <ErrorHandler error={error} handleClose={this.handleClose.bind(this)} />
        }

        function Done(e) {
            this.props.customFileData(this.props.askingFor[0].file, { departureCountry: e.departureCountry });
            this.props.shiftAskFor();
        }

        function Cancel(e) {
            this.props.shiftAskFor();
        }

        const ShowRequestBox = () => {
            if (this.props.askingFor.length > 0) {
                return <EbayDataModal title={this.props.askingFor[0].file + " Departure Country"} open={this.props.askingFor.length > 0 && this.props.askingFor[0].ebay} onDone={Done.bind(this)} onCancel={Cancel.bind(this)} />
            }

            return null;
        }

        const CreateTabs = e => {
            return <YearTabs years={years} onSelect={(index, key) => this.setState({ tabKey: key })} />
        };

        return (
            <div className={classes.root + " print-full-width"}>
                {CreateTabs()}
                <TabContainers years={years} calculations={calcs} minDate={minDate} ids={ids} viesChecks={viesChecks} index={tabKey} rowsProcessed={rowsProcessed} duplicates={duplicates} />
                {CreateErrorBox(this.props.error)}
                {ShowRequestBox()}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        files: state.files,
        ids: state.ids,
        duplicates: state.duplicates,
        selectedFiles: state.selectedFiles,
        dateRanges: state.dateRanges,
        selectedCountries: state.selectedCountries,
        salesData: state.salesData,
        availableCountries: state.availableCountries,
        availableDates: state.availableDates,
        salesDataProcessed: state.salesDataProcessed,
        updateIndex: state.updateIndex,
        totalUpdates: state.totalUpdates,
        error: state.error,
        vatRates: state.vatRates,
        skippedVATNumbers: state.skippedVATNumbers,
        fileUpdates: state.fileUpdates,
        askingFor: state.askingFor,
        clientView: state.clientView,
        years: state.years,
        processedFiles: state.processedFiles,
        removeDuplicates: state.removeDuplicates,
        clientInfo: state.clientInfo
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setCountries: countries => dispatch(setCountries(countries)),
        setAvailableCountries: countries => dispatch(setAvailableCountries(countries)),
        setAvailableDates: dates => dispatch(setAvailableDates(dates)),
        setLoading: progress => dispatch(setLoading(progress)),
        hideLoading: () => dispatch(hideLoading()),
        processFile: file => dispatch(processFile(file)),
        processFiles: (files, dateRanges, skippedVATNumbers) => dispatch(processFiles(files, dateRanges, skippedVATNumbers)),
        setError: message => dispatch(setError(message)),
        skipVATNumber: vatNumber => dispatch(skipVATNumber(vatNumber)),
        shiftAskFor: () => dispatch(shiftAskFor()),
        customFileData: (desc, data) => dispatch(customFileData(desc, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DefaultView));
