export default function loadFile(file) {
    return new Promise((resolve, err) => {
        const temporaryFileReader = new global.window.FileReader();

        temporaryFileReader.onerror = e => {
            temporaryFileReader.abort();
            err(new DOMException("Problem parsing input file."));
        };

        temporaryFileReader.onload = e => {
            resolve(temporaryFileReader.result);
        };

        temporaryFileReader.readAsBinaryString(file);
    });
}
